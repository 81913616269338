import React, { useContext } from 'react';
import MyButton from '../../../../_utility-components/button/button';
import { Delivery } from '../../dto/delivery';
import pochtaLogo from '../../../../../img/ems-pochta.png';
import pickpointLogo from '../../../../../img/pick-logo.png';
import sdekLogo from '../../../../../img/sdek-logo.png';
import { OrderDeliveryContext } from './delivery';
import { batch } from 'react-redux';
import { removeMessage } from 'src/scripts/functions';
import { OrderContext } from '../../order-page';

export function DeliveryItemHandler({
  delivery: deliveryToSelect,
}: {
  delivery: Delivery
}) {
  const {
    delivery,
    orderDeliveries,
    initDeliveryCreation,
    setCurrentDeliveryId,
    updateOrderDeliveriesCollection,
  } = useContext(OrderDeliveryContext)

  const {
    isOrderUnchangeable
  } = useContext(OrderContext)
  
  const deliveryItemImage = (() => {
    switch(+deliveryToSelect.id) {
      case 2: return pochtaLogo;
      case 3: return pochtaLogo;
      case 7: return pickpointLogo;
      case 9: return sdekLogo;
      default: return null;
    }
  })()

  const deliveryItemHandler = (selectedDelivery: Delivery) => {
    if(isOrderUnchangeable || (delivery && delivery.type === +selectedDelivery.id))
      return false;

    const existedSelectedDelivery = orderDeliveries?.find(d => d.type === +selectedDelivery.id)
    const removeDeliveryError = () => removeMessage('.order-page-delivery')

    if(existedSelectedDelivery) {
      setCurrentDeliveryId(existedSelectedDelivery.id)
      removeDeliveryError()
      return;
    }

    const newDelivery = initDeliveryCreation({
      type: +selectedDelivery.id,
      city: delivery?.city,
      address: delivery?.address,
      weight: delivery?.weight,
      height: delivery?.height,
      width: delivery?.width,
      length: delivery?.length,
      postcode: delivery?.postcode
    })

    if(!newDelivery) {
      return false
    }

    batch(() => {
      updateOrderDeliveriesCollection(
        orderDeliveries
          ? [...orderDeliveries, newDelivery]
          : [newDelivery]
      )
      setCurrentDeliveryId(newDelivery.id)
    })
    removeDeliveryError()
  }
  
  return <MyButton
    className="op-delivery-item-header"
    onClick={() => deliveryItemHandler(deliveryToSelect)}
  >
    <div className="op-delivery-item-check header-font-m">
      <div className="check-box-div"><div></div></div>
      <div>{deliveryToSelect.name}</div>
    </div>

    <div className="op-delivery-item-logo">
      {deliveryItemImage ?
        <img src={deliveryItemImage} alt="logo" />
      : null}
    </div>
  </MyButton>;
}
