import React, { useContext, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import useRouteId from '../../../../../scripts/hooks/use-route-id';
import MyButton from '../../../../_utility-components/button/button';
import { ProductItem, Order } from 'merchery-lib';
import { OrderContext } from '../../order-page';
import { CanBeAddedProduct } from '../../order-page-popups/can-be-added-product.dto';
import ItemsListFooter from './items-list-footer';
import ItemsListHeader from './items-list-header';
import ListBody from './list-body';
import withAddItemPopup from '../../order-page-popups/add-item-popup';
import ProductToBeAddedInOrder from '../../order-page-popups/product-row-to-be-added';

export const AddItemsInOrder = withAddItemPopup<CanBeAddedProduct>(ProductToBeAddedInOrder)

function ItemsList() {
  const {
    order, orderChange,
  } = useContext(OrderContext);

  const items = useAppSelector(state => state.productItemsInContext)
  const orders = useAppSelector(state => state.orders)
  const _isMounted = useMounted()
  const orderId = useRouteId('orderid')

  const dispatch = useDispatch()
  const orderChangesDispatch = (changes: Partial<Order>) => 
    dispatch({type: 'ORDERS', payload: orders.map(o => o.id === orderId ? {...o, ...changes} : o)})
  const orderItemsDispatch = (items: ProductItem[]) => 
    dispatch({type: 'PRODUCT_ITEMS_IN_CONTEXT', payload: items})
  const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);

  const addItemsInOrder = async (items: CanBeAddedProduct[]): Promise<boolean> => { 
    const res = await mercheryFetch<{order: Order, items: ProductItem[]}>(`orders/item`, "POST", {
      orderId: orderId,
      items: items
    })

    if(!_isMounted.current || !validateResponse(res))  {
      return false
    }

    batch(() => {
      orderChangesDispatch(res.records.order)
      orderItemsDispatch(res.records.items || items)
    })
    return true
  };

  if(!order) {
    return null
  }

  return (
    <div className="table-items grid-child-table">
      {items.length ? 
        <>
          <div id='table-items'>
            <ItemsListHeader/>

            <ListBody order={order}/>
          </div>

          <ItemsListFooter
            order={order}
            setAddItemPopupOpen={setAddItemPopupOpen}
            changeData={orderChange}
          />
        </> 
      : 
        <div className="order-page-empty-order">
          <div className="inner-wrapper-empty-order">
            <div className="title-div">Товары</div>

            <MyButton
              className={'white-blue-btn'} 
              onClick={() => setAddItemPopupOpen(true)}
            >
              + Добавить товар
            </MyButton>
          </div>
        </div>
      }

      {addItemPopupOpen ?
        <AddItemsInOrder
          popupClose={() => setAddItemPopupOpen(false)}
          addItems={addItemsInOrder} 
          filters={{}}
          withTotalSum={true}
          />
      : null}
    </div>
  );
}

export default ItemsList;