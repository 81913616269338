import React, { MouseEvent } from "react";
import MyButton from "../../../_utility-components/button/button";
import { ClientDto } from 'merchery-lib';

interface Props {
  clientCheckHandler: (client: ClientDto) => void, 
  client: ClientDto
}

export function ClientRowIdAndCheck({
  client,
  clientCheckHandler
}: Props) {

  return <div className="id-and-check-wrapper table__cell">
    <MyButton
      removeDefaultClass
      onClick={(e: MouseEvent) => {
        if (e.shiftKey) e.preventDefault();
        clientCheckHandler(client);
      }}
      className='select-outside'
    >
      <div className="checkbox"><div></div></div>
    </MyButton>
    
    <div>{client.id}</div>
  </div>;
}
