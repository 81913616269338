import {DimensionsPreset, OrderDelivery} from "../../dto/delivery";
import Bubbles from "src/components/_utility-components/bubbles";
import MyInput from "src/components/_utility-components/input";
import React, {useContext} from "react";
import {OrderDeliveryContext} from "./delivery";
import {batch} from "react-redux";
import {useAppSelector} from "src/scripts/pre-type/use-selector";

export function DeliveryDimensions () {
  const settings = useAppSelector(state => state.settings)
  const {
    delivery,
    changeSelectedDelivery,
  } = useContext(OrderDeliveryContext);
  const selectDimensionsPreset = (item: DimensionsPreset) => {
    changeSelectedDelivery({
      width: item.width,
      height: item.height,
      length: item.length
    })
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: keyof OrderDelivery
  ) => {
    const value = e.target.value;

    batch(() => {
      changeSelectedDelivery({[label]: value})
    })
  };
  const deliveryDimensionsPresets = settings.find(setting => setting.callname === 'dimensions_presets')?.value as undefined | null | DimensionsPreset[]

  return <>
    <div className="delivery-dimesions-presets__wrapper">
      <h4 className="wide-text-font-xxs">
        ПАРАМЕТРЫ ПОСЫЛКИ
      </h4>

      {deliveryDimensionsPresets ?
        <Bubbles
          onClick={selectDimensionsPreset}
          items={deliveryDimensionsPresets.map(preset => ({...preset, id: preset.name}))}
          tooltipConstructor={item => `${item.length}x${item.width}x${item.height}`}
        />
        : null}
    </div>

    <div className="delivery-dimensions__inputs">
      <MyInput
        id="delivery-length"
        name="delivery-length"
        myClassName="delivery-length-input"
        placeholder="Длина, см"
        type={"number"}
        min={0}
        value={delivery?.length || ""}
        onChange={e => handleChange(e, 'length')}
      />

      <MyInput
        id="delivery-width"
        name="delivery-width"
        myClassName="delivery-width-input"
        placeholder="Ширина, см"
        type={"number"}
        min={0}
        value={delivery?.width || ""}
        onChange={e => handleChange(e, 'width')}
      />

      <MyInput
        id="delivery-height"
        name="delivery-height"
        myClassName="delivery-height-input"
        placeholder="Высота, см"
        type={"number"}
        min={0}
        value={delivery?.height || ""}
        onChange={e => handleChange(e, 'height')}
      />
    </div>
  </>;
}