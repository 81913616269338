import React, { useContext, useMemo } from 'react';
import { calcPrice, priceToPennies } from '../../../../../scripts/functions';
import withRowComponents, { RowProps } from '../../../../../scripts/with-row-components';
import { ProductItem, RefundProductItem } from 'merchery-lib';
import ProductItemCheckBox from '../../../products/products-items/check-box';
import ProductItemCount from '../../../products/products-items/count';
import ProductItemImage from '../../../products/products-items/image';
import ProductItemName from '../../../products/products-items/name';
import { RefundStartContext } from './refund-start';
import RefundTotalField from './refund-total-field';

function ProductRowToBeRefunded({
  item,
}: {
  item: ProductItem,
}) {
  const itemChanger = (item: Partial<ProductItem>) => {}
  const { selectedItems, setSelectedItems } = useContext(RefundStartContext)

  const thisSelectedItem = useMemo(() => selectedItems.find(arrayItem => arrayItem.id === item.id), [item, selectedItems])

  const addSelected = (initCount: number = 1) => {
    setSelectedItems([...selectedItems, ({
      ...item,
      refundCount: initCount,
      refundTotal: priceToPennies(+calcPrice([{
        count: initCount, 
        price: item.price_with_discount
      }])),
    })])
  }

  const removeSelected = () => {
    setSelectedItems(selectedItems.filter(selected => item.id !== selected.id))
  }
  
  const changeCanBeAddedSelected = (changes: Partial<RefundProductItem>) => {
    setSelectedItems(selectedItems.map(selected => 
      item.id === selected.id ? {...selected, ...changes} : selected
    ))
  }

  const checkboxHandler = (checkboxItem: RefundProductItem) => {
    const selected = selectedItems.some(selected => selected.id === checkboxItem.id)

    if(selected) {
      removeSelected()
    } else {
      addSelected()
    }
  }

  const selected = selectedItems.some(selected => item.id === selected.id)

  const counter = (newCount: number) => {
    if(selected && newCount > item.count) {
      // const message = countOverRemainMessage(item.count)
      // toastUp(message)

      return false
    }

    if(newCount === 0) {
      removeSelected()
    } else if(!thisSelectedItem && newCount > 0) {
      addSelected(newCount)
    } else {
      changeCanBeAddedSelected({
        refundCount: newCount,
        refundTotal: priceToPennies(+calcPrice([{
          count: newCount, 
          price: item.price
        }])),
      })
    }
  }

  const itemCountInOrder = item.count

  const itemShownAttributes = [
    'checkbox', 
    'src', 
    'name', 
    { key: 'count', options: { 
      hideUnit: true
    } },
    { key: 'refundCount', options: { 
      count: thisSelectedItem?.refundCount || 0, 
      counter: counter, 
      remain: itemCountInOrder,
      customArrow: true,
      hideUnit: true,
      width: 60
    } }, 
    'refundTotal'
  ] as const;

  return (
    <RefundProductItemRow
      shownFields={itemShownAttributes}
      componentsFields={refundItemFields}
      item={thisSelectedItem || {
        ...item,
        refundCount: 0,
        refundTotal: 0,
      }}
      className={'row-item'}
      selected={selected}
      changer={itemChanger}
      selectHandler={checkboxHandler}
    />
  );
}

export default ProductRowToBeRefunded;

export const RefundProductItemRow: React.FC<RowProps<RefundProductItem>> = withRowComponents<RowProps<RefundProductItem>>(() => <></>);

export const refundItemFields = {
  checkbox: ProductItemCheckBox, 
  src: ProductItemImage, 
  name: ProductItemName, 
  count: ProductItemCount,
  refundCount: ProductItemCount,
  refundTotal: RefundTotalField
}

// export function countOverRemainMessage(remain: number): string {
//   return `Количество товаров на возврат превышает количество товара в заказе (${remain})`
// }
