import {useEffect, useState} from "react";
import {useAppSelector} from "../pre-type/use-selector";

export function useItemsMass () {
	const items = useAppSelector(state => state.productItemsInContext);

	const calc = () => {
		let newMass = 0;

		for (let index = 0; index < items.length; index++) {
			const element = items[index];
			newMass += (element.weight || 0) * element.count;
		}

		return newMass
	}

	const [mass, setMass] = useState(calc())

	useEffect(() => {
		const newMass = calc()

		if(newMass !== mass) {
			setMass(newMass)
		}

	}, [items, mass]);

	return mass;
}