import React, { useRef} from 'react';
import Dropzone, { DropEvent, DropzoneProps, DropzoneRef, ErrorCode, FileRejection } from 'react-dropzone';
import { ImageExtended, ImageFile } from 'merchery-lib';
import UploadImage from '../../img/upload-image';
import {formatBytes, toastUp} from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import LocalLoader from './loaders/local-loader';
import {MyResponse} from "src/scripts/fetchConstructor";

export type DropzoneFileHandler = (newFiles: File[], dropzoneElement?: HTMLDivElement | null) => 
  void | Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>>;

export enum DropzoneAccept {
  WEBM = 'video/webm',
  MP4 = 'video/mp4',
  M4V = 'video/x-m4v',
  VIDEO = 'video/*',
  WEBP = 'image/webp',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf',
}

export const dropzoneAcceptAllVideoPreset = [DropzoneAccept.WEBM, DropzoneAccept.MP4, DropzoneAccept.M4V, DropzoneAccept.VIDEO];
export const dropzoneAcceptAllImagePreset = [DropzoneAccept.WEBP, DropzoneAccept.JPEG, DropzoneAccept.SVG, DropzoneAccept.PNG, DropzoneAccept.GIF, DropzoneAccept.PDF];

interface Props {
  files: (ImageExtended | ImageFile)[],
  fileHandler: DropzoneFileHandler,
  contentRender: React.ReactElement<any, any> | JSX.Element,
  optionalProps?: Partial<DropzoneProps & React.RefAttributes<DropzoneRef>>,
  className?: string,
  disabled?: boolean,
  isLoading?: boolean,
  emptyRender?: React.ReactElement<any, any> | JSX.Element,
  accept: typeof DropzoneAccept[keyof typeof DropzoneAccept][]
}

export const postBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
	new Promise((resolve, reject) => {
		try {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		} catch (error) {
			reject(error)
		}
	});

function MyDropzone ({
  files,
  fileHandler,
  optionalProps,
  className,
  contentRender,
  disabled,
  isLoading,
  emptyRender,
  accept,
}: Props) {
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const maxSize = useAppSelector(state => state.staticValues.dropzone_file_max_size || undefined)
  const errorMessage = useAppSelector(state => state.staticValues.dropzone_error_message || '')

  const onDropAccepted = async (newFiles: File[], event: DropEvent) => {
    if(newFiles?.length) {
      fileHandler(newFiles, dropzoneRef.current);
    }
  }

  const onDropRejected = async (fileRejections: FileRejection[], event: DropEvent) => {
    console.log('fileRejections', fileRejections)
    for (const {errors, file} of fileRejections) {
      const messagePrefix = `Файл ${file.name} не загружен:`;

      for (const err of errors) {
				const format = maxSize ? '(' + formatBytes({ bytes: maxSize }) + ')' : '';

        const message = (() => {switch (err.code) {
          case ErrorCode.FileInvalidType: 
            return `${messagePrefix} неподдерживаемый формат изображения`;
          case ErrorCode.FileTooLarge: 
            return `${messagePrefix} больше допустимоего размера в ${maxSize} бит ${format}`;
          default: return errorMessage;
        }})()
        
        toastUp(message)
      }
    }
  }

  if(isLoading) {
    return (
      <div className={`dropzone dropzone--loading ${className ? className : ''}`}>
        <LocalLoader 
          className={''}
          height={32}
          width={32}
        />
      </div>
    )
  }

  const dropzoneAccept = accept ? accept.join(', ') : 'image/webp, image/jpeg, image/svg+xml, image/png, image/gif, application/pdf, video/webm, video/mp4, video/x-m4v, video/*'

  return (
    <Dropzone
      accept={dropzoneAccept}
      multiple={optionalProps?.multiple || true}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      maxSize={maxSize}
      disabled={disabled}
      maxFiles={optionalProps?.maxFiles || 10}
      {...optionalProps}
    >
      {({getRootProps, getInputProps}) => (
        <div
          className={`dropzone ${className ? className : ''}`}
          {...getRootProps()}
          ref={dropzoneRef}
        >
          <input {...getInputProps({id: `dropzone-input`})}/>

          {files?.length ? (
            contentRender
          ) : null}

          {!files?.length ?
            !emptyRender ? (
              <div className="without-files">
                <div className="files-load-image">
                  <UploadImage/>
                </div>
                <div className="files-load-image files-load-image__has-error">
                  <UploadImage color='#4A5267'/>
                </div>

                <div className={'without-files__text'}>
                  <h4>Загрузить изображения</h4>
                  <div className="dropzone-text large">или перетащите файлы в это поле для загрузки .jpg, .png, .webp, .gif</div>
                  <div className="dropzone-text small">.jpg, .png, .webp, .gif</div>
                </div>
              </div>
            ) : <>{emptyRender}</>
          : null}
        </div>
      )}
    </Dropzone>
  )
}

export default MyDropzone