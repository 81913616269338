import React, { useState, useEffect } from 'react';
import qs from 'qs';
import MyButton from '../../components/_utility-components/button/button';
import { validateResponse } from '../functions';
import { mercheryFetch } from '../fetchConstructor';
import Popup, { PopupProps } from "../../components/_utility-components/popup"
import { Id } from 'merchery-lib';
import { batch } from 'react-redux';
import useMounted from './use-mounted';

type PossibleValue<K extends string> = {
  id: Id;
} & Record<K, string | number | null>;

interface IPossibleResponse<T extends PossibleValue<K>, K extends string = 'name'>{
  dataLoading: boolean;
  search: string;
  setSearch: (search: string) => void;
  data: T[]
  setData: (data: T[]) => void
  showData: boolean;
  setShowData: (show: boolean) => void;
}

/**
 * @function usePossibleValues
 * @description A custom React hook that fetches possible values based on a search term and provides a popup with these values.
 * @param {Object} params - An object containing the URL path for the fetch request and a mutable reference object that indicates whether the component is mounted.
 * @param {string} params.urlPath - The URL path for the fetch request.
 * @param {Object} params.[requestFilters] - The request filters for more flexible the fetch request.
 * @returns {IPossibleResponse<T>} An object containing the following:
 * dataLoading: A boolean flag shown .
 * search: The current search term.
 * setSearch: A function to update the search term.
 * data: An array of possible values.
 * setData: A function to update the array of possible values.
 * showData: A boolean indicating whether to show the data.
 * setShowData: A function to update whether to show the data.
 * @template T - A type that extends an object with an id and a name. 
 */
const usePossibleValues = <T extends PossibleValue<K>, K extends string = 'name'>({
  urlPath,
  params,
}: {
  urlPath: string,
  params?: { [key: string]: any },
  textField?: string
}): IPossibleResponse<T, K> => {
  const _isMounted = useMounted()
  const [search, setSearch] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState<T[]>([]);
  const [showData, setShowData] = useState(false);
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(search) {
        batch(() => {
          setDataLoading(true)
          setShowData(true)
          searchAttribute(search)
        })
      } else {
        setShowData(false)
      }
    }, 300) 

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const searchAttribute = async (search: string) => {
    try {
      const query = qs.stringify({
        search: search,
        ...(params && {...params})
      }, {arrayFormat: 'comma'})
  
      const valuesRes = await mercheryFetch<T[]>(`${urlPath}?${query}`, 'GET')

      _isMounted.current && setDataLoading(false)
  
      if(!_isMounted.current || !validateResponse(valuesRes)) {
        return false;
      }

      setData(valuesRes.records || [])
      setDataLoading(false)

      return valuesRes.records
    } catch (error) {
      console.log(error)
    }
  }

  return {
    dataLoading,
    setSearch,
    search,
    data,
    setData,
    showData,
    setShowData,
  }
}

interface PossibleValuesPopupProps<T extends PossibleValue<K>, K extends string = 'name'> {
  dataLoading: boolean;
  data: T[]
  showData: boolean;
  search: string;
  withAddNew?: undefined | ((name: string) => void)
  clickHandler: (data: T) => void
  setShowData: (show: boolean) => void
  textField?: K
  className?: string
  changingDirection?: PopupProps['changingDirection']
}

const PossibleValuesPopup = <T extends PossibleValue<K>, K extends string = 'name'>({ 
  data, 
  dataLoading, 
  showData,
  search,
  withAddNew,
  clickHandler,
  setShowData,
  textField = 'name' as K,
  className = '',
  changingDirection = true
}: PossibleValuesPopupProps<T, K>) => {
  if(!showData) {
    return null;
  }

  const exactMatch = data.some(item => search == item[textField]);

  return (
    <Popup
      className={`possible-values-popup ${className}`}
      popupClose={() => setShowData(false)}
      changingDirection={changingDirection}
    >
      <div className='possible-values-wrapper popup-group'>

        <PossibleValuesLoading dataLoading={dataLoading}/>

        <ValuesNotFound<T, K> dataLoading={dataLoading} data={data}/>

        <CreateNewValue 
          dataLoading={dataLoading} 
          withAddNew={withAddNew} 
          exactMatch={exactMatch} 
          search={search}/>

        <Values<T, K>
          dataLoading={dataLoading} 
          data={data} 
          clickHandler={clickHandler} 
          textField={textField}        
        />
      </div>
    </Popup>
  )
}

export {
 usePossibleValues,
 PossibleValuesPopup,
}

function Values<T extends PossibleValue<K>, K extends string = 'name'>({ dataLoading, data, clickHandler, textField }: { dataLoading: boolean; data: T[]; clickHandler: (data: T) => void; textField: K; }) {
  if(dataLoading || !data.length)
    return null;

  return (
    data.map((n, index) => 
      <MyButton
        key={n.id}
        removeDefaultClass
        onClick={() => clickHandler(n)}
        className='popup-element'
      >
        {n[textField]}
      </MyButton>
    )
  )
}

function CreateNewValue({ dataLoading, withAddNew, exactMatch, search }: { dataLoading: boolean; withAddNew: ((name: string) => void) | undefined; exactMatch: boolean; search: string; }) {
  if(dataLoading || withAddNew === undefined || exactMatch)
    return null

  return (
    <div
      className={'popup-element blue-color'}
      onClick={() => withAddNew(search)}
    >
      Создать "{search}"
    </div>
  )
}

function ValuesNotFound<T extends PossibleValue<K>, K extends string = 'name'>({ dataLoading, data }: { dataLoading: boolean; data: T[]; }) {
  if(dataLoading || data.length) {
    return null
  }

  return (
    <div className='popup-element'>
      Ничего не найдено
    </div>
  )
}

function PossibleValuesLoading({dataLoading}: {dataLoading: boolean}) {
  if(!dataLoading) {
    return null
  }

  return (
    <div className='popup-element'>
      Загрузка...
    </div>
  )
}
