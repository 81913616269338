import React, { useEffect, useMemo, useState } from 'react';
import MyDropzone, { dropzoneAcceptAllImagePreset, dropzoneAcceptAllVideoPreset, postBase64 } from 'src/components/_utility-components/dropzone';
import DropzoneContent from 'src/components/_utility-components/dropzone-content';
import { validateResponse } from 'src/scripts/functions';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import useMounted from 'src/scripts/hooks/use-mounted';
import { ExtendedProductStoriesItem, Image, ImageExtended, MyResponse, ImageDeleteResponse, ImageFile } from 'merchery-lib';
import { extractImages } from 'src/scripts/utils/extractImages';
import UploadImage from 'src/img/upload-image';

interface IProps {
  item: ExtendedProductStoriesItem,
  itemDispatch: (item: ExtendedProductStoriesItem) => void
}

function ProductStoriesItemImage({ item, itemDispatch }: IProps) {
  const _isMounted = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLocal, setImageLocal] = useState<ImageExtended | ImageFile | null>(item.image)
  
  useEffect(() => {
    if(
      (item.image === null && imageLocal !== null) ||
      (item.image !== null && imageLocal === null) ||
      (item.image?.id && imageIsImageFile(imageLocal))
    ) {
      setImageLocal(item.image)
    }
  }, [item.image])
  
  const fileHandler = async (files: File[]) => {
    const file = files[0]

    const isVideo = file.type.includes('video');
    const fileURL = URL.createObjectURL(file)

    const imageProps = {
      imageName: file.name,
      order: 1,
      src: fileURL,
      newFile: true,
      videoSource: null,
      videoLink: isVideo ? fileURL : null,
      image: isVideo ? file : String(await postBase64(file)),
    }

    setImageLocal(imageProps)
    
    return undefined
  }

  useEffect(() => {
    if(imageIsImageFile(imageLocal) && !item.newItem) {
      sendImage()
    }
  }, [imageLocal])
 
  const deleteImage = async () => {
    if(!imageLocal) 
      return false

    if('imageName' in imageLocal) {
      setImageLocal(null);
      return false
    }

    return mercheryFetch<ImageDeleteResponse>('images', 'DELETE', {
      filters: {
        module_id: item.id,
        module: 'Stories'
      }
    })
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      itemDispatch({
        ...item,
        image: null,
      })
    })
  }
  
  const extractedImages = useMemo(() => 
    extractImages(item.image, 'small')
  , [item.image])
  
  const sendImage = async (): Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>> => {
    if(!item || !imageLocal || !imageIsImageFile(imageLocal)) {
      return undefined
    }

    setLoading(true);

    if(item.image) {
      await deleteImage()
    }

    const requestBody = {
      module_id: item.id,
      newImages: [imageLocal],
      cropSizes: ['original', 'large', 'small'],
      module: 'Stories'
    }

    return mercheryFetch<ImageExtended[]>('images/create', 'POST', requestBody, {
      withoutToastUp: true
    })
    .then((res) => {
      if(!_isMounted.current || !res.success) return res;

      const createdImage = res.records.at(0);
      if(createdImage) {
        itemDispatch({
          ...item,
          image: createdImage,
        })
      }

      return res
    })
    .finally(() => {
      if(_isMounted.current) {
        setLoading(false);
      }
    })
  }

  return (
    <div className='product-stories-item__image product-stories-item__input'>
      <h4 className="header-font-s">Изображение</h4>
      
      <MyDropzone
        files={
          imageLocal 
            ? [imageLocal] 
            : []
        } 
        optionalProps={{
          multiple: false,
          maxFiles: 1,
        }}
        accept={[...dropzoneAcceptAllVideoPreset, ...dropzoneAcceptAllImagePreset]}
        isLoading={loading}
        disabled={item.newItem}
        fileHandler={fileHandler}
        emptyRender={<GetEmptyRender item={item}/>}
        contentRender={
          <DropzoneContent
            extractedImages={extractedImages}
            deleteImage={deleteImage}
            fallBackLink={imageLocal?.src || null}
          >
            <div 
              className='product-stories-item__image__text-preview' 
              style={item.color ? { color: '#' + item.color } : undefined}
            >
              <div style={{ textAlign: 'center', fontWeight: 600, fontSize: '8px' }}>
                {item.name}
              </div>
              <div style={{ textAlign: 'center', fontWeight: 400, fontSize: '5px' }}>
                {item.description}
              </div>
            </div>
          </DropzoneContent>
        }
      />
    </div>
  );
}

function GetEmptyRender ({ item }: {item: ExtendedProductStoriesItem}) {
  if(item.newItem) {
    return <div className='product-stories-item__image__without-files without-files'>
		  <p className='product-stories-item__image__without-files-text'>
        Загрузка доступна после сохранения</p>
    </div>
  }

	return <div className={'product-stories-item__image__without-files without-files'}>
		<div className="files-load-image">
			<UploadImage/>
		</div>

		<div className="files-load-image files-load-image__has-error">
			<UploadImage color='#4A5267'/>
		</div>
    
		<p className='product-stories-item__image__without-files-text'>
      Формат 9:16 .jpg, .png, .webp
    </p>
	</div>;
}

export default ProductStoriesItemImage;

function imageIsImageFile(image: Image | ImageFile | null): image is ImageFile {
  return image ? 'imageName' in image : false
}