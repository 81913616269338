import React from 'react';
import { batch, useDispatch } from 'react-redux';
import { num2str, validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyCheckbox from '../../../../_utility-components/checkbox/my-checkbox';
import ItemsListHeaderCells from './items-list-header-cells';
import OrderItemsDelete from '../popups/order-items-delete';
import { useLoad } from '../../../../../scripts/hooks/use-load';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import { useRouteMatch } from 'react-router';
import { OrderWithItems } from '../../dto/order-responses.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { ProductItem, Order, Id } from 'merchery-lib';

function ItemsListHeader() {
  const selectedItems = useAppSelector(state => state.selectedProductItemsInContext);
  const items = useAppSelector(state => state.productItemsInContext);
  const allSelected = Boolean(items.length && selectedItems.length && selectedItems.length === items.length);
  const someSelected = !!selectedItems.length;
  const [ , setload ] = useLoad();
  const _isMounted = useMounted()
  const orders = useAppSelector(state => state.orders)

  const match = useRouteMatch<{orderid: string}>()
  const orderId = +match.params.orderid
  const order = orders.find(o => o.id === orderId)

  const checkboxHandler = () => setSelectedItems(selectedItems.length === items.length ? [] : items.map(item => item.id))

  const dispatch = useDispatch();
  const setSelectedItems = (ids: Id[]) => dispatch({ type: 'PRODUCT_ITEMS_IN_CONTEXT_SELECTED', payload: ids})
  const orderDispatch = (order: Order) => dispatch({type: 'ORDERS', payload: orders.map(o => o.id === orderId ? order : o)})
  const itemsDispatch = (items: ProductItem[]) => dispatch({ type: 'PRODUCT_ITEMS_IN_CONTEXT', payload: items })

  const deleteItem = async () => {
    setload(true)
    const res = await mercheryFetch<OrderWithItems>('orders/item', 'DELETE', {
      id: selectedItems,
      orderId: orderId
    })

    setload(false)

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    batch(() => {
      orderDispatch(res.records.order)
      itemsDispatch(res.records.items)
      setSelectedItems([])
    })
  };

  if(!order) {
    return null
  }

  return (
    <div className='order-items__header'>
      <div className={someSelected ? 'row-item selected' : 'row-item'}>
        {selectedItems?.length ? (
          <SelectedItemsBar 
            selected={allSelected} 
            deleteItem={deleteItem} 
            checkboxHandler={checkboxHandler}
          />
        ) : (
          <ItemsListHeaderCells
            order={order}
          />
        )}
      </div>
    </div>
  );
}

export default ItemsListHeader;

interface SelectedItemsBarProps {
  selected: boolean,
  deleteItem: () => void,
  checkboxHandler: () => void
}

function SelectedItemsBar({
  selected,
  deleteItem,
  checkboxHandler,
}: SelectedItemsBarProps) {
  const selectedItems = useAppSelector(state => state.selectedProductItemsInContext);
  const text = num2str(selectedItems.length, ['товар', 'товара', 'товаров'], ['Выбран', 'Выбрано', 'Выбрано']);

  return <>
    <MyCheckbox
      className='hide-for-print'
      condition={selected}
      actionHandler={checkboxHandler} 
      />

    <div className="selected-items-menu">
      {text}
      
      <div className='selected-items-control'>
        <OrderItemsDelete 
          deleteItem={deleteItem}
          />
      </div>
    </div>
  </>;
}
