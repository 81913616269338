import React, {useEffect, useState} from 'react';
import {MainRouteChild} from "src/components/main-pages/main-page";
import {useLocation} from "react-router-dom";
import {mercheryFetch} from "src/scripts/fetchConstructor";
import {querify, validateResponse} from "src/scripts/functions";
import {PaymentDto} from "src/components/main-pages/_DEV/payment-dto/payment.dto";
import useMounted from "src/scripts/hooks/use-mounted";
// @ts-ignore
import queryString from "query-string";

interface Props extends MainRouteChild {
}

type CheckoutLocationSearchParams = {
	payment_id?: string;
	order_id?: string;
};

function Checkout (props: Props) {
	const _isMounted = useMounted();
	const [payment, setPayment] = useState<PaymentDto | null>(null)

	const location = useLocation();
	const {
		payment_id,
		order_id
	} = queryString.parse(location.search) as CheckoutLocationSearchParams;

	useEffect(() => {
		if(!payment_id || !order_id) {
			return;
		}

		const body = {
			payment_id,
			order_id
		}

		mercheryFetch<PaymentDto>(`payment/check-or-confirm`, 'POST', body)
		.then(res => {
			if (!_isMounted.current || !validateResponse(res)) {
				return false;
			}

			setPayment(res.records)
		})
	}, [])


	if(!payment) {
		return null
	}

	if(payment.status === 'pending') {
		return <div>
			Ожидается оплата со стороны клиента
		</div>
	}

	if(payment.status === 'cancel') {
		return <div>
			Оплата не прошла
		</div>
	}

	if(payment.status === 'success') {
		return <div>
			Успешно оплачено
		</div>
	}

	return (
		<div>
			Что-то пошло не так
		</div>
	);
}

export default Checkout;