import {useTabIndex} from "src/scripts/hooks/use-tabindex";
import React, {useContext, useEffect} from "react";
import {OrderDeliveryContext} from "../delivery";
import useSortableData, {SortableDataConfig} from "src/scripts/hooks/use-sortable-data";
import MyTable from "src/components/_utility-components/common-table/table";
import {HeaderRow} from "src/components/_utility-components/common-table/header-row";
import HeaderCell from "src/components/_utility-components/common-table/header-cell";
import {TableBody} from "src/components/_utility-components/common-table/table-body";
import {BodyRow} from "src/components/_utility-components/common-table/body-row";
import {BodyCell} from "src/components/_utility-components/common-table/body-cell";
import MyCheckbox from "src/components/_utility-components/checkbox";
import {TariffNames, tariffNames} from "./dto/postprice.ru.utils";
import {capitalize} from "lodash";
import {RussianPostContext} from "./russian-post-ems";
import DeliveryTariffsLoader from "src/components/_utility-components/loaders/cdek-tariffs-loaders";
import {RussianPostCalc, RussianPostCalcWithError, RussianPostExtendedTariff} from "./dto/rpost-calc.dto";
import {num2str, priceWithPennies, ptsansCurrency} from "src/scripts/functions";

export type ExtendedWithNameTariff = RussianPostExtendedTariff & { name: string };

export function RussianPostTariffsTable () {
	const tabIndex = useTabIndex();

	const {
		delivery,
		changeSelectedDelivery
	} = useContext(OrderDeliveryContext);

	const {
		tariffs: unsortedTariffs,
		tariffsLoading,
		setCurrentTariff,
		currentTariff,
	} = useContext(RussianPostContext);

	const defaultSorting: SortableDataConfig<ExtendedWithNameTariff> = {
		key: 'price',
		direction: 'ascending',
	};

	const extendedWithTextTariffs: ExtendedWithNameTariff[] =
		unsortedTariffs
			// .filter(tariff => tariff.price) // price === 0 tariff not work
			.filter(tariff => !isTariffWithErrors(tariff))
			.map(tariff => ({
				...tariff,
				name: tariffNames[tariff.id as keyof TariffNames],
			}))

	const [ tariffs, requestSort, config ] = useSortableData(
		extendedWithTextTariffs,
		defaultSorting
	);

	useEffect(() => {
		const noTariffs = !tariffs.length;
		const noCurrentTariffInTariffsList = currentTariff && !noTariffs && !tariffs.some(tariff => tariff.id === currentTariff.id);
		if (noTariffs || noCurrentTariffInTariffsList) {
			setCurrentTariff(undefined);
			return;
		}

		if (delivery && tariffs.length && !delivery.tariff_id && !currentTariff) {
			setCurrentTariff(tariffs[0]);
		}
	}, [tariffs])

	useEffect(() => {
		if (currentTariff && currentTariff.id !== delivery?.tariff_id) {
			changeSelectedDelivery({
				tariff_id: String(currentTariff.id),
				cost: groundRate(currentTariff),
			});
		}
	}, [currentTariff]);

	useEffect(() => {
		if(delivery?.tariff_id && tariffs?.length) {
			const foundTariff = tariffs.find(tariff =>
				tariff.id === delivery.tariff_id
			);

			if(foundTariff) {
				setCurrentTariff(foundTariff)
			}
		}
	}, [delivery?.tariff_id]);

	if(tariffsLoading) {
		return <DeliveryTariffsLoader/>
	}

	if(!tariffs.length) {
		return <div>Нет доступных способов отправки для данного оператора</div>
	}

	return tariffs.length
		?	<MyTable id={'cdek-tariffs'} myClassName={'cdek-tariffs__table'}>
			<HeaderRow className='cdek-tariffs__header'>
				<HeaderCell<ExtendedWithNameTariff>
					children={'тариф'}
					label={'name'}
					config={config}
					handler={requestSort}
				/>

				<HeaderCell<ExtendedWithNameTariff>
					children={'срок доставки'}
					className={'right-align'}
					label={'delivery-time.min-days'}
					config={config}
					handler={requestSort}
				/>

				<HeaderCell<ExtendedWithNameTariff>
					children={'стоимость'}
					className={'right-align'}
					label={'ground-rate.rate'}
					config={config}
					handler={requestSort}
				/>
			</HeaderRow>

			<TableBody>
				{tariffs
					.map((tariff) => {
						const deliveryDateText = getDeliveryDateText(tariff);
						const tariffSelected = String(tariff.id) === delivery?.tariff_id;

						return (
							<BodyRow
								key={tariff.id}
								className={`cdek-tariffs__row text-font-m ${tariffSelected ? 'active' : ''}`}
								tabIndex={tabIndex}
								onClick={() => setCurrentTariff(tariff)}
							>
								<BodyCell myClassName='cdek-tariffs__tariff-name-cell'>
									<MyCheckbox
										isRadio={true}
										stylePreset={'inner-point'}
										condition={tariffSelected}
										actionHandler={() => {}}
									/>
									{capitalize(tariff.name)}
								</BodyCell>

								<BodyCell myClassName={'right-align'}>
									{deliveryDateText}
								</BodyCell>

								<BodyCell myClassName={'right-align'}>
									{priceWithPennies(groundRate(tariff))} {ptsansCurrency('₽')}
								</BodyCell>

							</BodyRow>
						);
					})}
				</TableBody>
			</MyTable>
		: null;
}

function isTariffWithErrors (tariff: RussianPostCalc | RussianPostCalcWithError): tariff is RussianPostCalcWithError {
	return (tariff as RussianPostCalcWithError).errors !== undefined
}

export function groundRate(tariff: RussianPostExtendedTariff) {
	if(isTariffWithErrors(tariff)) {
		return null
	}

	return tariff["ground-rate"].rate + tariff["ground-rate"].vat
}

function getDeliveryDateText (tariff: RussianPostExtendedTariff) {
 	if(isTariffWithErrors(tariff) || !tariff?.["delivery-time"]) {
		return '-'
	}

	const minDays = tariff["delivery-time"]?.["min-days"];
	const maxDays = tariff["delivery-time"]["max-days"];

	if(tariff["delivery-time"]["min-days"] === tariff["delivery-time"]["max-days"]) {
		return num2str(maxDays, ['день', 'дня', 'дней'])
	}

	const isMinDaysUndefined = minDays === undefined;
	if(isMinDaysUndefined) {
		return `до ${num2str(maxDays, ['день', 'дня', 'дней'])}`
	}

	return `${minDays}-${num2str(maxDays, ['день', 'дня', 'дней'])}`
}