import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { ImageExtended, SortingItem, CollectionDto, ImageDeleteResponse, ImageFile } from 'merchery-lib';
import {mercheryFetch, MyResponse} from 'src/scripts/fetchConstructor';
import { getObjectsDiffsByFields, querify, toastUp, uuidv4, validateResponse } from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import { NotFoundLocalApp } from '../../_utility-components/not-found';
import PageLoading from '../../_utility-components/page-loading';
import SeoSection from '../categories/seo';
import { MainRouteChild } from '../main-page';
import CollectionSide from './modules/collection-side';
import CollectionContent from './modules/collection-content';
import CollectionSummary from './modules/summary';
import CollectionTopPanel from './modules/top-panel';
import useUnload from '../../../scripts/hooks/use-unload';
import useMounted from '../../../scripts/hooks/use-mounted';

export type CollectionImageModules = 'Collection' | 'OgCollection'

interface Props extends MainRouteChild {}

export const CollectionContext = createContext<{
  collection: CategoryWithOgSrcFile | null,
  isNew: boolean
}>({
  collection: null,
  isNew: false
})

export type CategoryWithOgSrcFile = Omit<CollectionDto, 'og_src'> & {og_src?: ImageFile | ImageExtended | null};

function CollectionPage(props: Props) {
  const _isMounted = useMounted()

  const match = useRouteMatch<{id: string}>();
  const isNew = match.params.id === 'new';
  const currentId = +match.params.id;
  const history = useHistory()

  const tabIndex = useTabIndex(1)

  const collections = useAppSelector(state => state.collections || []);
  const thisCollection = useMemo(() => 
    collections.find(coll => isNew 
      ? coll.newCollection === true 
      : coll.id === +currentId)
  , [collections, currentId, isNew]);

  const labelsToFindDiffs = useMemo((): (keyof CollectionDto)[] => [
    'name', 'url', 'sorting_id', 'template_id', 'description', 
    'seo_description','og_title', 'seo_title', 'og_description',
    'show_date'
  ], []);

  const isCurrentCollection = useCallback((c: CollectionDto): boolean =>
    isNew 
      ? c.newCollection === true 
      : c.id === +currentId
  , [currentId, isNew])

  const [initCollection, setInitCollection] = useState(thisCollection);
  const [loaded, setLoaded] = useState(!!thisCollection);

  const collectionChanges = useMemo(() => 
    thisCollection 
      ? getObjectsDiffsByFields<CollectionDto>(thisCollection, initCollection, labelsToFindDiffs) 
      : {}
  , [initCollection, labelsToFindDiffs, thisCollection]);

  const hasChanges = useMemo(() => 
    !!Object.keys(collectionChanges).length
  , [collectionChanges]);

  const dispatch = useDispatch();
  const sortingDispatch = (items: SortingItem[]) => 
    dispatch({type: 'SORTING_ITEMS', payload: items})
  
  const collectionsDispatch = (collections: CollectionDto[]) => 
    dispatch({type: 'COLLECTIONS', payload: collections})

  const thisCollectionDispatch = (changedCollection: Partial<CollectionDto>) => {
    if(thisCollection) {
      const changedCollections = collections.map(c => 
        isCurrentCollection(c) 
          ? {...thisCollection, ...changedCollection} 
          : c
      )

      collectionsDispatch(changedCollections)
    }
  }

  const collectionChanger = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name: labelName, value} = e.target;
    thisCollectionDispatch({[labelName]: value})
  }

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  }, hasChanges);
  
  useEffect(() => {
    props.setCurrentPage('collections');

    if(!isNew) {
      getCollection()
    } else if(!thisCollection) {
      addCollection()
    }

    getSorting()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getSorting = () => {
    mercheryFetch<SortingItem[]>('sorting', 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      sortingDispatch(res.records)
    })
  }

  const getCollection = () => {
    if(isNew) {
      setLoaded(true)
      return false;
    }
    
    const query = querify({
      filters: {id: currentId}
    });

    mercheryFetch<CollectionDto>(`collections/one-extended?${query}`, 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res) || !res.records) return false

      batch(() => {
        setLoaded(true)
        setInitCollection(res.records)
        collectionsDispatch(
          collections.length 
            ? collections.map(c => 
                c.id !== res.records.id ? c : res.records) 
            : [res.records]
        )
      })
    })
  }

  const deleteImage = async (module: CollectionImageModules) => {
    const fieldName: keyof CollectionDto = module === 'Collection' ? 'src' : 'og_src';
    if(!thisCollection) return false;

    return mercheryFetch<ImageDeleteResponse>('images', 'DELETE', {
      filters: {
        module_id: thisCollection.id,
        module: module
      }
    })
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      batch(() => {
        thisCollectionDispatch({[fieldName]: undefined})
      })
    })
  }

  const sendImage = async (newFiles: ImageFile[], module: CollectionImageModules): Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>> => {
    const isOgImage = module === 'OgCollection'
    const fieldName: keyof CollectionDto = isOgImage ? 'og_src' : 'src';
    const newImage = newFiles.at(0);

    if(!thisCollection || !newImage) {
      return undefined
    }

    if(thisCollection[fieldName]) {
      await deleteImage(module)
    }

    const requestBody = {
      module_id: thisCollection.id,
      newImages: newFiles,
      cropSizes: ['original', 'medium', 'small'],
      module
    }

    return mercheryFetch<ImageExtended[]>('images/create', 'POST', requestBody, {
      withoutToastUp: true
    })
    .then((res) => {
      if(!_isMounted.current || !res.success) return res;

      const createdImage = res.records.at(0);
      createdImage && batch(() => {
        thisCollectionDispatch({[fieldName]: createdImage})
      })

      return res
    })
  }

  const updateCollection = async () => {
    if(collectionChanges && Object.keys(collectionChanges).length && thisCollection) {
      await mercheryFetch<CollectionDto[]>('collections', 'PATCH', {
        toChange: [{
          id: thisCollection.id, 
          ...collectionChanges
        }],
        returnExtended: true,
      })
      .then(res => {
        if(!_isMounted.current) return false;
        if(!validateResponse(res)) {
          toastUp(res.message)

          initCollection && thisCollectionDispatch(initCollection)
          return false
        } 

        const updatedCollection = res.records.find(c => c.id === thisCollection.id);
        updatedCollection && batch(() => {
          thisCollectionDispatch(updatedCollection)
          setInitCollection(updatedCollection)
        })
      })
    }

    if(collectionChanges.og_src) {
      await deleteImage('OgCollection')
    }
  }

  const createCollection = () => {
    if(!collections) {
      return false
    }
    mercheryFetch<CollectionDto>('collections', 'POST', {
      ...thisCollection,
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      const collectionsWithoutNew = collections.filter(c => !c.newCollection)

      batch(() => {
        collectionsDispatch([...collectionsWithoutNew, res.records])
        setInitCollection(res.records)
      })
      return res.records.id
    })
    .then((id) => {
      if(id) {
        history.replace('/app/collections/' + id)
      }
    })
  }

  const addCollection = () => {
    if(!collections) {
      return false
    }

    const arrayOfOrders = [...collections.map((c) => c.order), 0];
    const maxOrder = Math.max.apply(null, arrayOfOrders);
    const newOrder = maxOrder + 1;

    const newCollection: CollectionDto = {
      id: uuidv4(),
      name: '',
      url: '',
      order: newOrder,
      show_date: null,
      sorting_id: 1,
      template_id: null,
      description: null,
      seo_title: null,
      seo_description: null,
      og_title: null,
      og_description: null,
      items: [],
      newCollection: true
    }

    batch(() => {
      setLoaded(true)
      collectionsDispatch([...collections, newCollection])
      setInitCollection(undefined)
    })
  }

  const toClients = useMemo(() => ({
    pathname: '/app/collections',
    state: {
      fromCollection: +currentId,
    }
  }), [currentId]);

  const cancelBtnHandler = () => {
    if(initCollection) {
      thisCollectionDispatch(initCollection)
    }
    
    if(isNew) {
      history.push(toClients)
    }
  }

  return (
    <CollectionContext.Provider value={{
      collection: thisCollection || null,
      isNew,
    }}>
      <PageLoading
        loaded={loaded}
      >
        {thisCollection ? 
          <div className='collection-page'>
            <div className="collection-page__header">
              <Link tabIndex={0} 
                to={toClients} 
                className="to-products-btn"
              >
                <i className="icofont-simple-left"></i>
              </Link>

              <h1>{thisCollection.name || 'Новая коллекция'}</h1>
            </div>

            <CollectionSummary
              collectionChanger={collectionChanger}
              collectionDispatch={thisCollectionDispatch}
              sendImage={sendImage}
              deleteImage={deleteImage}
              thisCollection={thisCollection}
            />

            <CollectionContent 
              collectionDispatch={thisCollectionDispatch}
            />

            <SeoSection
              changer={collectionChanger}
              sendImage={files => sendImage(files, 'OgCollection')}
              deleteImage={() => deleteImage('OgCollection')}
              dispatch={thisCollectionDispatch}
              item={thisCollection}
              disabled={thisCollection.newCollection}
            />

            <CollectionSide 
              collection={thisCollection}
              collectionDispatch={thisCollectionDispatch}
            />

            <CollectionTopPanel 
              hasChanges={hasChanges}
              promptWhen={_isMounted.current}
              tabIndex={tabIndex}
              isNew={isNew}
              cancelBtnHandler={cancelBtnHandler}
              createCollection={createCollection}
              updateCollection={updateCollection}
            />
          </div>
        : <NotFoundLocalApp
          optionalMessage={'Такой коллекции не существует'}
        />}
      </PageLoading>
    </CollectionContext.Provider>
  );
}

export default CollectionPage;