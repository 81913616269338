import React, {useContext} from 'react';
import {useAppSelector} from 'src/scripts/pre-type/use-selector';
import {OrderDeliveryContext} from '../delivery';
import {SelectedDeliveryAddressAndCost} from './address-and-cost';
import {SelectedDeliveryImage} from './image';
import {SelectedDeliveryFooter} from './footer';
import {SelectedDeliveryContextMenu} from "./context-menu";

export function SelectedDeliveryBody() {
  const {
    delivery
  } = useContext(OrderDeliveryContext)
  const deliveries = useAppSelector(state => state.deliveryServices)

  if(!delivery) {
    return null
  }
  
  const curDelId = +delivery.type;
  const thisDelivery = deliveries.find(d => d.id === curDelId);

  if(!thisDelivery) {
    return null
  }

  return <>
    <div className="op-delivery-center op-delivery_element-paddings">
      <div className="left">
        <SelectedDeliveryImage/>
      </div>
      
      <div className="right">
        {/*<OrderDeliveryChangeStateSwitcher/>*/}
        <SelectedDeliveryContextMenu/>
      </div>

      {/* {!creationState && (curDelId === 7) ?
        <CreateDeliveryNote
        />
      : null} */}
    </div>

    <SelectedDeliveryAddressAndCost/>

    <SelectedDeliveryFooter/>
  </>;
}