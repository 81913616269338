import React, { useMemo, useState } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import ClientOrderHeader from './client-order-header';
import OrderDeliveryView from '../../orders/order-modules/order-delivery-view';
import { Order, ProductItem } from 'merchery-lib';
import { ProductItemRow } from '../../products/products-items/product-items';
import { productLikeFields } from '../../products/products-items/product-items-utils';
import { ShownFields } from 'src/scripts/with-row-components';

interface Props {
  order: Order,
  index: number
}

const productShownAttributes: ShownFields<ProductItem> = [
  'src', 
  'name',
  'external_id',
  'returned',
  {key: 'price', options: {priceField: 'price_with_discount'}},
  'count', 
  'total'
]

function ClientOrder({
  order,
  index
}: Props) {
  const items = useAppSelector(state => state.productItemsInContext);
  const statuses = useAppSelector(state => state.orderStatus);

  const filteredItems = items.filter(item => item.order === order.id)

  const currentStatus = useMemo(() =>
    statuses && statuses.find(s => s.id === order.status) || undefined
  , [order.status, statuses]);

  const [orderOpened, setOrderOpened] = useState(index === 0);

  return (
    <div className='client-order'>

      <ClientOrderHeader order={order}
        currentStatus={currentStatus}
        openHandler={setOrderOpened}
        orderOpened={orderOpened}
      />

      <div className={`client-order__items ${!orderOpened ? 'display-none' : ''}`}>
        {filteredItems.map(item => (
          <ProductItemRow
            key={item.id}
            className={'row-item'}
            shownFields={productShownAttributes}
            componentsFields={productLikeFields}
            item={item}
            changer={undefined} 
            disabled={false}
          />
        ))}
      </div>

      <OrderDeliveryView 
        orderOpened={orderOpened}
      />
    </div>
  );
}

export default ClientOrder;