import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { ProductItem, RefundDto } from 'merchery-lib';
import { ProductItemRow } from '../../../products/products-items/product-items';
import { productLikeFields } from '../../../products/products-items/product-items-utils';

interface Props {
  refund: RefundDto
}

function RefundedItems({
  refund
}: Props) {
  const items = useAppSelector(state => state.productItemsInContext);
  const refundedItems: ProductItem[] = useMemo(() => items.flatMap(item => {
    const refundeditem = refund.returned_items.find(returnedItem => returnedItem.id === item.id);
    if(!refundeditem) {
      return []
    }

    return {
      ...item,
      count: refundeditem.count
    }
  }), [items, refund])

  const productShownAttributes = [
    'src', 
    { key: 'name', options: {} }, 
    { key: 'count', options: {
      hideUnit: true,
    } },
    { key: 'total', options: {
      withMinus: true
    }},
  ] as const;

  return (
    <div className='order-page__refund__body'>
      {refundedItems
      .map((item) => 
        <ProductItemRow
          key={item.id}
          shownFields={productShownAttributes}
          componentsFields={productLikeFields}
          item={item}
          className={'row-item'}
        />
      )}
    </div>
  );
}

export default RefundedItems