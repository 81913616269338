import React from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { ClientDto } from 'merchery-lib';
import { TopSummary } from "./top-summary";

export function BonusPoints({
  client
}: {
  client: ClientDto
}) {
  const pointsTurnedOn = useAppSelector(
    state =>
      state.settings.find(
        setting =>
          setting.callname === 'client_loyalty_points_enabled'
      )?.value
  )

  const points = (client.loyalty?.bonus_points || 0) + '';

  if(!pointsTurnedOn) {
    return null
  }

  return <TopSummary header={'Бонусы'}
    mainText={points}
    bottomText={`детализация`} 
  />;
}
