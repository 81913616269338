import React, { useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import useMounted from 'src/scripts/hooks/use-mounted';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { getObjectsDiffsByFields, priceWithPennies, priceWithStep, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useUnload from '../../../../scripts/hooks/use-unload';
import TopPagePanel from '../../../_utility-components/top-page-panel';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import { ClientDto } from 'merchery-lib';
import { LoyaltyCard } from './loyalty-card';
import { BonusPoints } from './bonus-points';
import { TopSummary } from './top-summary';

interface Props {
  client: ClientDto
}

function ClientSummary({
  client
}: Props) {
  const orders = useAppSelector(state => state.orders)
  const pointsTurnedOn = useAppSelector(state => state.settings.find(setting => setting.callname === 'client_loyalty_points_enabled')?.value)
  const _isMounted = useMounted()
  const labelsToFindDiffs = useMemo(() => [
    'name', 'phone', 'email'
  ], []);
  const clients = useAppSelector(state => state.clients);
  const [initClient, setInitClient] = useState(client);

  const diffs = useMemo(() => initClient ? getObjectsDiffsByFields<ClientDto>(client, initClient, labelsToFindDiffs) : {}, [client, initClient, labelsToFindDiffs])
  const hasChanges = useMemo(() => !!Object.keys(diffs).length, [diffs])

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  }, hasChanges);

  const dispatch = useDispatch();
  const clientsDispatch = (clients: ClientDto[]) => dispatch({type: 'CLIENTS', payload: clients});
  const thisClientDispatch = (changedClient: Partial<ClientDto>) => 
    clientsDispatch(
      (clients || []).map(c => 
        c.id !== client.id ? c : {...client, ...changedClient}
      )
    )

  const changeClient = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const labelName = event.target.name;
    const value = event.target.value;

    thisClientDispatch({[labelName]: value})
  }

  const cancelChanges = () => {
    thisClientDispatch(initClient)
  }

  const updateClient = async () => {
    const patchRes = await mercheryFetch<ClientDto[]>('client', 'PATCH', { 
      changes: [{
        id: client.id,
        ...diffs
      }]
    })

    if(!_isMounted.current || !validateResponse(patchRes)) return false

    const thisClient =  patchRes.records.find(changedClient => changedClient.id === client.id) 

    if(!thisClient) {
      return false
    }

    batch(() => {
      thisClientDispatch(thisClient)
      setInitClient(thisClient)
    })
  }
  const completedOrders = orders.filter(order => 
    order.client_id === client.id && 
    order.status === 3
  )

  return (
    <div className={`client-summary ${!pointsTurnedOn ? 'client-summary__points--off' : ''}`}>
      <TopSummary 
        header={'Выполненные заказы'} 
        mainWithColor
        mainText={'' + completedOrders.length} 
        withCurrency
        bottomText={`на сумму ${
          priceWithStep(
            priceWithPennies(
              completedOrders.reduce((total, order) => total + (order.total || 0), 0)
            )
          )
        }`}
      />

      <TopSummary 
        header={'Возвраты'} 
        mainWithColor
        mainText={'' + client.refundsCount} 
        withCurrency
        bottomText={`на сумму ${
          priceWithStep(
            priceWithPennies(
              client.refundTotal
            )
          )
        }`}
      />

      {client.ordersTotal !== null && client.refundTotal !== null ?
        <TopSummary 
          header={'Выкуп'} 
          withCurrency
          mainText={'' + client.buyout + ' %'}
          bottomText={`на сумму ${priceWithStep(priceWithPennies(client.ordersTotal - client.refundTotal))}`}
        />
      : null}

      <BonusPoints 
        client={client}      
      />

      <div className='client-page__main-labels client-summary__grid-element'>
        
        <MyInput myClassName={'grid-element'}
          name={'name'}
          required={false}
          onChange={changeClient}
          value={client.name}
        >
          <h5 className="char-label__header header-font-s">ФИО</h5>
        </MyInput>

        <MyInput myClassName={'grid-element'}
          name={'email'}
          required={false}
          onChange={changeClient}
          value={client.email}
        >
          <h5 className="char-label__header header-font-s">Email</h5>
        </MyInput>

        <MyInput myClassName={'grid-element'}
          name={'phone'}
          required={false}
          onChange={changeClient}
          value={client.phone}
        >
          <h5 className="char-label__header header-font-s">Телефон</h5>
        </MyInput>
      </div>

      <LoyaltyCard
        changeClient={changeClient}
        client={client}
      />

      <TopPagePanel fixed
        topPanelOpened={hasChanges}
      >
        
      <Prompt
        when={hasChanges}
        message='Остались несохраненные изменения, покинуть страницу?'
      />
        <div className="left">
          <div className="text-div">Несохраненные изменения</div>
        </div>

        <div className="right">
          <MyButton
            id={'product-cancel-btn'}
            className="dark-btn"
            onClick={cancelChanges}
          >
            Отменить
          </MyButton>

          <MyButton
            id={'product-confirm-btn'}
            className="blue-btn"
            onClick={updateClient}
          >
            Сохранить
            </MyButton>
        </div>
      </TopPagePanel>
    </div>
  );
}

export default ClientSummary;