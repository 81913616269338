import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ImageExtended, ImageFile } from 'merchery-lib';
import { extractImages } from '../../../scripts/utils/extractImages';
import MyCheckbox from '../../_utility-components/checkbox/my-checkbox';
import MyInput from '../../_utility-components/input/index';
import MyDropzone, { dropzoneAcceptAllImagePreset, postBase64 } from '../../_utility-components/dropzone';
import {MyResponse} from "../../../scripts/fetchConstructor";
import DropzoneContent from "../../_utility-components/dropzone-content";

export interface SeoFields {
  seo_title?: string | null,
  seo_description?: string | null,
  og_description?: string | null, 
  og_title?: string | null, 
  og_src?: ImageExtended | null
}

interface Props<T extends SeoFields> {
  changer: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  sendImage: (newFiles: ImageFile[]) => Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>>,
  deleteImage: () => Promise<false | undefined>,
  dispatch: (categoryProps: Partial<SeoFields>) => void,
  item: T,
  disabled?: boolean,
}

function SeoSection<T extends SeoFields,>({
  changer,
  sendImage,
  deleteImage,
  dispatch,
  item,
  disabled,
}: Props<T>) {
  const [hasOpengraphValues, setHasOpengraphValues] = useState(Boolean(item?.og_description || item?.og_title || item?.og_src));
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if(item && (item.og_description || item.og_title)) {
      setHasOpengraphValues(true)
    }
  }, [item])

  const extractedImages = useMemo(() => 
    extractImages(item.og_src, 'small')
  , [item?.og_src])

  const checkboxHandler = useCallback(() => {
    const newValue = !hasOpengraphValues
    
    if(newValue === false) {
      dispatch({
        og_title: null,
        og_description: null,
        og_src: undefined
      })
    }

    setHasOpengraphValues(newValue)
  }, [hasOpengraphValues])

  if(!item) {
    return null
  }

  const fileHandler = async (files: File[]) => {
    setLoading(true);

    let imageFiles: ImageFile[] = []

    for (const file of files) {
      const isVideo = file.type.includes('video');
      const fileURL = URL.createObjectURL(file)

      const imageProps = {
        imageName: file.name,
        order: 1,
        src: fileURL,
        newFile: true,
        videoSource: null,
        videoLink: isVideo ? fileURL : null,
      }

      imageFiles.push({
        ...imageProps,
        image: isVideo ? file : String(await postBase64(file)),
      })
    }

    const res = await sendImage(imageFiles)
    setLoading(false);
    return res
  }

  return (
    <section className='seo'>
      <div className='seo__header side-padding-24'>
        <div>
          <h3 className='header-font-l'>SEO</h3>
        </div>
        <div>
          <Link to={'/app/about#seo'} className={"text-link"}>
            Как это работает?
          </Link>
        </div>
      </div>

      <div className='seo__inner side-padding-24'>
        <MyInput myClassName={'grid-element seo_title'}
          name={'seo_title'}
          required={false}
          onChange={changer}
          value={item.seo_title || ''}
          placeholder={'Заголовок вкладки в браузере'}
          noPlaceholder
        >
          <h5 className="char-label__header header-font-s">Title</h5>
        </MyInput>

        <MyInput myClassName={'grid-element seo_desc'}
          type={'textarea'}
          name={'seo_description'}
          required={false}
          onChange={changer}
          value={item.seo_description || ''}
          placeholder={'Meta-описание'}
          noPlaceholder
        >
          <h5 className="char-label__header header-font-s">Description</h5>
        </MyInput>

        <MyCheckbox 
          className='grid-element seo-checkbox'
          condition={hasOpengraphValues} 
          text={'Задать отдельные значения для Opengraph-разметки'} 
          actionHandler={checkboxHandler}
        />

        {hasOpengraphValues ? <>
          <MyInput myClassName={'grid-element og_title'}
            name={'og_title'}
            required={false}
            onChange={changer}
            value={item.og_title || ''}
            noPlaceholder
            placeholder={'заголовок'}
          >
            <h5 className="char-label__header header-font-s">og:title</h5>
          </MyInput>

          <MyInput myClassName={'grid-element og_desc'}
            type={'textarea'}
            name={'og_description'}
            required={false}
            onChange={changer}
            value={item.og_description || ''}
            noPlaceholder
            placeholder={'описание'}
          >
            <h5 className="char-label__header header-font-s">og:description</h5>
          </MyInput>

          
          <div className='grid-element og-dropzone'>
            <h5 className="char-label__header header-font-s">og:image</h5>
            <MyDropzone
              files={item.og_src ? [item.og_src] : []}
              optionalProps={{
                multiple: false,
                maxFiles: 1,
                disabled: disabled
              }}
              accept={[...dropzoneAcceptAllImagePreset]}
              isLoading={loading}
              disabled={disabled}
              fileHandler={fileHandler}
              contentRender={
                <DropzoneContent
                  extractedImages={extractedImages}
                  deleteImage={deleteImage}
                  fallBackLink={item.og_src?.src || null}
                />
              }
            />
          </div>
        </> : null}
      </div>
    </section>
  );
}

export default SeoSection;