import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import { ClientDto } from 'merchery-lib';
import {usePopup} from "../../../../scripts/hooks/use-popup";
import { ClientCreation } from '../dto/client.dto';

function ClientCreate() {
  const _isMounted = useRef(true)
  const clients = useAppSelector(state => state.clients);

  const dispatch = useDispatch()
  const clientsDispatch = (client: ClientDto) => dispatch({
    type: "CLIENTS",
    payload: [...(clients || []), client]
  })

  const [newClient, setNewClient] = useState<ClientCreation>({
    name: '',
    email: '',
    phone: '',
  });

  const updateClient = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const labelName = event.target.name;
    const value = event.target.value;

    setNewClient({...newClient, [labelName]: value })
  }

  const {
    RenderPopup,
    RenderButton,
    closePopup,
  } = usePopup()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])
  
  const createClient = () => {
    mercheryFetch<ClientDto>('client', 'POST', {
      createData: newClient
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      closePopup()
      clientsDispatch(res.records)
    })
  }

  return (
    <div>
      <RenderButton className='blue-btn text-font-m'>
        Добавить покупателя
      </RenderButton>

      <RenderPopup
        tabIndexDeep={3}
        withBlackout
        withCloseBtn
        className='fixed-on-center client-create-popup'
      >
        <h3 className='header-font-l'>
          Добавление покупателя
        </h3>
        <div className='client-page__main-labels'>
          <MyInput myClassName={'grid-element'}
            name={'name'}
            required={false}
            onChange={updateClient}
            onEnter={createClient}
            value={newClient.name}
          >
            <h5 className="char-label__header header-font-s">ФИО</h5>
          </MyInput>

          <MyInput myClassName={'grid-element'}
            name={'email'}
            required={false}
            onChange={updateClient}
            onEnter={createClient}
            value={newClient.email}
          >
            <h5 className="char-label__header header-font-s">Email</h5>
          </MyInput>

          <MyInput myClassName={'grid-element'}
            name={'phone'}
            required={false}
            onChange={updateClient}
            onEnter={createClient}
            value={newClient.phone}
          >
            <h5 className="char-label__header header-font-s">Телефон</h5>
          </MyInput>
        </div>

        <div className='client-create-popup__btn-wrapper'>
          <MyButton className='blue-btn'
            onClick={createClient}
          >
            Сохранить
          </MyButton>

          <MyButton className='white-btn'
            onClick={closePopup}
          >
            Отменить
          </MyButton>
        </div>
      </RenderPopup>
    </div>
  );
}

export default ClientCreate;