import React from 'react';
import { ItemCellProps } from 'src/scripts/with-row-components';
import { calcPrice, numWithSep, priceWithPennies, ptsansCurrency } from '../../../../scripts/functions';
import { ExtendedProduct, ProductItem } from 'merchery-lib';

type ProductItemTotalPropsWithTotal = {
  total: number,
  withMinus?: boolean
}

type ProductItemTotalPropsWithprice = {
  count: number, 
  price: number,
  withMinus?: boolean
}

export type ProductItemTotalProps<T extends ProductItem | ExtendedProduct> = (ProductItemTotalPropsWithTotal | ProductItemTotalPropsWithprice) & ItemCellProps<T>

function ProductItemTotal<T extends ProductItem | ExtendedProduct>(props: ProductItemTotalProps<T>) {

  let totalPriceText: string = '';
  const currencyText = ptsansCurrency('₽')

  if(hasTotalProperty(props)) {
    totalPriceText = numWithSep(
      priceWithPennies(
        calcPrice([{
          price: props.total, 
          count: 1
        }])
      )
    )
  } else if(hasPriceProperty(props)) {
    totalPriceText = numWithSep(
      priceWithPennies(
        calcPrice([{
          price: props.price, 
          count: props.count
        }])
      )
    )
  }
  return (
    <div className='row-item__total right-align'>
      <span style={{textAlign: 'right'}}>
      {props.withMinus ? '– ' : ''}{totalPriceText}&#160;{currencyText}

      </span>
    </div>
  );
}

export default ProductItemTotal;

function hasTotalProperty(props: ProductItemTotalProps<any>): props is ProductItemTotalPropsWithTotal & ItemCellProps<any> {
  return typeof (props as ProductItemTotalPropsWithTotal).total !== 'undefined';
}
function hasPriceProperty(props: ProductItemTotalProps<any>): props is ProductItemTotalPropsWithprice & ItemCellProps<any> {
  return typeof (props as ProductItemTotalPropsWithprice).price !== 'undefined' && typeof (props as ProductItemTotalPropsWithprice).count !== 'undefined';
}