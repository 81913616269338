/* eslint-disable */
import React, {  } from 'react';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import MyButton from './button/button';
import screencast from 'src/img/video-test.webm';

const Playground = () => {

  return (
    <div>
      <video src={String(screencast)} width={300} height={150} autoPlay={false} muted/>
    </div>
  );
}

export default Playground;