import React, { memo, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { ExtendedProduct, Id, ProductAttr, ProductVariantExtended } from 'merchery-lib';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import { uuidv4, validateResponse } from 'src/scripts/functions';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { useLoad } from '../../../scripts/hooks/use-load';
import useMounted from '../../../scripts/hooks/use-mounted';
import usePageViewers from "../../../scripts/hooks/use-page-viewers";
import useRouteId from '../../../scripts/hooks/use-route-id';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import { NotFoundLocalApp } from '../../_utility-components/not-found';
import { MSProductImages } from '../../integrations-modules/moysklad/ms-product-images';
import { MainRouteChild } from '../main-page';
import ProductHeader from './product-page-modules/header';
import ProductMainLabels from './product-page-modules/main-labels';
import ProductImagesDropzone from './product-page-modules/media';
import ProductOptions from './product-page-modules/options';
import ProductDescription from './product-page-modules/description';
import ProductProperties from './product-page-modules/properties';
import RightSideBar from './product-page-modules/right-side-bar';
import ProductTopPanel from './product-page-modules/top-panel';
import ProductVariants from './product-page-modules/variants';
import RelatedProducts from './product-page-modules/analog-and-related/related-products';
import AnalogProducts from './product-page-modules/analog-and-related/analog-products';
import ProductStories from './product-page-modules/stories';

interface ProductPageProps extends MainRouteChild {
  pageState: 'change' | 'create'
}

export type ProductInputHandler = <T extends keyof ExtendedProduct>(label: T, value: ExtendedProduct[T]) => false | undefined

export interface ProductPageLocationState {
  prevPage: string,
  categoryFilter?: Id | undefined
}

export const ProductPageContext = React.createContext<{
  managersOnPage: Array<Id>,
  optionsLoaded: boolean,
  setOptionsLoaded: (_loaded: boolean) => void,
}>({
  optionsLoaded: false,
  setOptionsLoaded: (_loaded: boolean) => {},
  managersOnPage: [],
});

function Product ({
  pageState,
  setCurrentPage
}: ProductPageProps) {
  const notFoundNotification = useAppSelector(state => state.staticValues.not_found_product_page_notification);
  const product = useAppSelector(state => state.product);
  
  const moySkladIntegrationOn = useAppSelector(state =>
    state.integrations?.find(s => 
      s.code === 'moy_sklad')?.turned_on || false)
  
  const notfoundContextLinks = [{
    to: '/app/products',
    text: 'К списку товаров',
  }]

  const isCreate = pageState === 'create';
  const productid = useRouteId('productid');
  const initProduct = useRef<ExtendedProduct | undefined>(undefined);

  const [, setLoad] = useLoad();
  const [notFound, setNotFound] = useState(false);
  const _isMounted = useMounted();

  const dispatch = useDispatch();
  const productDispatch = (product: ExtendedProduct | undefined) =>
    dispatch({ type: 'PRODUCT_ITEM', payload: product });

  const variantsDispatch = (variants: ProductVariantExtended[]) =>
    dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants });

  const initVariantsDispatch = (variants: ProductVariantExtended[]) =>
    dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants });

  const selectedVariantAttributesDispatch = (attrs: ProductAttr[][]) =>
    dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_ATTRIBUTES', payload: attrs })

  const [optionsLoaded, setOptionsLoaded] = useState(false);
  // const {
  //   lastMessage,
  // } = useContext(WebSocketContext);

  // useEffect(() => {
  //   if (lastMessage?.data) {
  //     const message = JSON.parse(lastMessage.data) as WsResponse<UpdateProductsWsDto>;

  //     if (message.event === updateProduct &&
  //       product &&
  //       message.data.changes && (
  //         !Array.isArray(message.data.changes) ||
  //         message.data.changes.length === 1
  //       )
  //     ) {
  //       const changes = Array.isArray(message.data.changes) 
  //         ? message.data.changes[0]
  //         : message.data.changes;

  //       if(changes.id === product.id) {
  //         batch(() => {
  //           productDispatch({...product, ...changes})
  //           if(initProduct.current) {
  //             initProduct.current = {...initProduct.current, ...changes}
  //           }
  //         })
  //       }
  //     }
  //   }
  // }, [lastMessage]);

  const location = useLocation<ProductPageLocationState>();
  const pathname = location.pathname;
  const viewersCount = usePageViewers(pathname);

  useEffect(() => {
    if(product && product.id !== productid) {
      startProductComponent()
    }
  }, [productid])

  useEffect(() => {
    setCurrentPage('products');

    if(isCreate) {
      initCreation()
    } else {
      startProductComponent()
    }

    return () => {
      productDispatch(undefined)
    }
  }, [])

  const initCreation = () => {
    const newProduct: ExtendedProduct = {
      id: uuidv4(),
      src: [],
      variants: [],
      anons: '',
      brand: 0,
      deleted: null,
      description: '',
      discount: 0,
      external_id: null,
      barcode: null,
      name: '',
      category_order: 0,
      main_page_order: 0,
      price: 0,
      price_old: 0,
      remain: 0,
      type: 1,
      purchase_price: 0,
      show_date: null,
      title: '',
      top: location.state.categoryFilter || 1,
      weight: 0,
      price_old_or_discount: 'discount',
      measure_id: 0,
      package_amount: null,
      ship_in_packages: false,
      price_per_pack: false,
      newProduct: true,
      vat: 1,
      created: null,
      modified: null,
      show_novelty_flag: false,
      categoryNav: ''
    };
    
    productDispatch(newProduct)
  }

  const inputHandler: ProductInputHandler = <T extends keyof ExtendedProduct,>(label: T, value: ExtendedProduct[T]) => {
    if(!product) {
      return false
    }

    productDispatch({
      ...product, 
      [label]: value
    })
  }

  const startProductComponent = async () => {
    setLoad(true)

    await getProduct()

    setLoad(false)
  }

  const getProduct = async () => {
    const res = await mercheryFetch<ExtendedProduct>(`products/${productid}`, 'GET')

    if(res.statusCode === 404) {
      setNotFound(true)
      return false
    }

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    const product = res.records;

    batch(() => {
      initProduct.current = product;
      productDispatch(product);
      variantsDispatch(product.variants);
      initVariantsDispatch(product.variants);
      selectedVariantAttributesDispatch([]);
    })

    return res
  }

  if(notFound) {
    return (
      <NotFoundLocalApp
        optionalMessage={notFoundNotification}
        contextLinks={notfoundContextLinks}
      />
    )
  }
  
  if(!product) {
    return null
  } 

  return (
    <ProductPageContext.Provider value={{
      optionsLoaded,
      setOptionsLoaded,
      managersOnPage: viewersCount,
    }}>
      <div className="product-page">
        <ProductHeader
          pageIsCreatePage={isCreate}
        />

        <RightSideBar />

        <div className='product-page__main-labels-and-media__wrapper'>
          <ProductMainLabels
            inputHandler={inputHandler}
          />

          {isCreate
            ? null
            : moySkladIntegrationOn
              ? <MSProductImages />
              : <ProductImagesDropzone />
          }
        </div>

        <ErrorBoundary
          FallbackComponent={FallbackComponent}
        >
          <div className='product-page__options-and-variants__wrapper'>
            {!moySkladIntegrationOn ? (
              <ProductOptions
                isCreate={isCreate}
                inputHandler={inputHandler}
              />
            ) : null}

            {!moySkladIntegrationOn ? (
              <ProductVariants />
            ) : null}
          </div>
        </ErrorBoundary>

        <ProductProperties
          inputHandler={inputHandler}
          isCreatePage={isCreate}
        />

        <ProductDescription
          pageIsCreatePage={isCreate}
          initProduct={initProduct}
        />
        
        <RelatedProducts />

        <AnalogProducts/>

        <ProductStories />

        <ProductTopPanel
          initProduct={initProduct}
          pageIsCreatePage={isCreate}
        />
      </div>
    </ProductPageContext.Provider>
  );
}

export default memo(Product)