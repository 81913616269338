import React, {useContext} from 'react';
import {mercheryFetch} from "src/scripts/fetchConstructor";
import MyButton from "src/components/_utility-components/button/button";
import {OrderDeliveryContext} from "src/components/main-pages/orders/order-modules/delivery/delivery";
import {OrderDelivery} from "src/components/main-pages/orders/dto/delivery";
import useMounted from "src/scripts/hooks/use-mounted";
import {validateResponse} from "src/scripts/functions";

function ContextMenuItems () {
	const _isMounted = useMounted();
	const {
		delivery,
		changeSelectedDelivery
	} = useContext(OrderDeliveryContext);

	const refreshData = () => {
		if(!delivery) {
			return false
		}

		const {
			note,
			id,
			type,
			delivery_service_uuid
		} = delivery;

		if(!note || !id || !type) {
			return false
		}

		mercheryFetch<OrderDelivery>(`orders/delivery/actualize`, 'POST', {
			deliveryNote: note,
			deliveryId: id,
			type: type,
			uuid: delivery_service_uuid,
		})
		.then(res => {
			if(_isMounted.current && validateResponse(res)) {
				console.log(res)
				changeSelectedDelivery(res.records)
			}
		})
	}

	if(!delivery?.note || delivery.receiving_date) {
		return null
	}

	return(
		<MyButton
			className='popup-element left-align'
			onClick={refreshData}
		>
			Обновить данные
		</MyButton>
	)
}

export default ContextMenuItems;