import {useAppSelector} from "../pre-type/use-selector";
import {useDispatch} from "react-redux";
import {arrayOfSelectedFilters, validateResponse} from "../functions";
import {useEffect} from "react";
import {ClientsFilters} from "../../components/main-pages/clients/dto/client-filters.dto";
import useTableFilters from "./use-table-filters";
import {mercheryFetch} from "../fetchConstructor";
import useMounted from "./use-mounted";
import { ClientDto, ClientGroup } from "merchery-lib";

function useClientFilters () {
	const _isMounted = useMounted()
	const clientGroups = useAppSelector(state => state.clientGroups);
	const clientsFilters = useAppSelector(state => state.clientsFilters);

	const dispatch = useDispatch()
	const filtersDispatch = (filters: Partial<ClientsFilters>) => dispatch({
		type: 'CLIENTS_FILTERS',
		payload: filters
	});
	const groupsDispatch = (groups: ClientGroup[]) => dispatch({
		type: 'CLIENT_GROUPS',
		payload: groups
	});

	const getGroups = async (): Promise<ClientGroup[] | false> => {
		return mercheryFetch<ClientGroup[]>('client/group', 'GET')
			.then((res) => {
				if(!_isMounted.current || !validateResponse(res)) {
					return false
				}

				groupsDispatch(res.records)
				return res.records
			})
	}

	async function initFilters (): Promise<ClientsFilters> {

		if(clientGroups) {
			return {
				group: groupsToFilters (clientGroups) || undefined
			}
		}

		const gotGroups = await getGroups()
		if(gotGroups) {
			return {
				group: groupsToFilters(gotGroups)
			}
		}

		return {
			group: undefined
		}
	}

	const [updateFilters, cleanFilters] = useTableFilters<ClientDto>(
		clientsFilters,
		filtersDispatch,
		initFilters,
	);

	useEffect((() => {
		if(clientsFilters && !clientsFilters.group && clientGroups) {
			filtersDispatch({
				...clientsFilters,
				group: groupsToFilters(clientGroups)
			})
		}
	}), [clientGroups])

	const groupsToFilters = (groups: ClientGroup[] | null) => {
		return groups ?
			arrayOfSelectedFilters(
				[
					{
						id: null,
						name: 'Без группы'
					},
					...groups
				] as ClientGroup[]
			) : undefined
	}

	return [clientsFilters, updateFilters, cleanFilters] as const
}

export default useClientFilters;