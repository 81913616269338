import QueryString from 'qs';
import React, {memo, useContext, useEffect, useMemo, useRef, useState} from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { batch, useDispatch } from 'react-redux';
import { Id, Order } from "merchery-lib";
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import {getObjectsDiffsByFields, scrollPageTo, uuidv4, validateResponse} from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useOrderDelivery from '../../hooks/use-order-delivery';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import FallbackComponent from '../../../../_utility-components/error-boundary';
import { Delivery, OrderDelivery } from '../../dto/delivery';
import { OrderContext } from '../../order-page';
import { DeliveriesToSelect } from './deliveries-to-select';
import { OrderDeliveryHeader } from './order-delivery-header';
import { SelectedDeliveryBody } from './selected-delivery/body';

export const OrderDeliveryContext = React.createContext<{
  currentDeliveryId: Id | null,
  orderDeliveries: OrderDelivery[] | null, 
  delivery: OrderDelivery | null,
  initDeliveryCreation: (initValue?: Partial<OrderDelivery>) => OrderDelivery | false,
  setCurrentDeliveryId: (id: Id | null) => void
  updateOrderDeliveriesCollection: (deliveries: OrderDelivery[]) => void,
  changeSelectedDelivery: (changes: Partial<OrderDelivery>) => void,
  setDeliverySelectStateOn: () => void,
  setDeliveryEditState: React.Dispatch<React.SetStateAction<boolean>>,
  safeHandler: (preventChangingDeliveryState?: true) => Promise<false | OrderDelivery>
  deleteHandler: () => unknown | Promise<unknown>
  itemsWeight: number | null,
  updateOrderDeliveriesList: (delivery: OrderDelivery, addDeliveryAsNew?: true | undefined) => false | void
}>({
  currentDeliveryId: null,
  orderDeliveries: null,
  delivery: null,
  initDeliveryCreation: () => false,
  setCurrentDeliveryId: () => {},
  updateOrderDeliveriesCollection: () => {},
  changeSelectedDelivery: () => {},
  setDeliverySelectStateOn: () => {},
  setDeliveryEditState: () => {},
  safeHandler: () => new Promise(() => false),
  deleteHandler: () => {},
  itemsWeight: null,
  updateOrderDeliveriesList: () => {}
})

interface Props {
  updateOrderState: (order: Partial<Order>) => void,
  className?: string,
}

function DeliverySection ({
  updateOrderState,
  className,
}: Props) {
  const {
    orderChange,
  } = useContext(OrderContext);

  const _isMounted = useMounted();
  const orders = useAppSelector(state => state.orders);
  const orderItems = useAppSelector(state => state.productItemsInContext);

  const {
    order,
  } = useContext(OrderContext);
  const scrollTrigger = useRef(false);

  const orderDelivery = useOrderDelivery(order);

  const [deliveryEditState, setDeliveryEditState] = useState(false);
  const [currentDeliveryId, setCurrentDeliveryId] = useState(order?.current_delivery_id || null);
  const [orderDeliveries, setOrderDeliveries] = useState(order?.deliveries || null);

  const [intermediateDelivery, setIntermediateDelivery] = useState<OrderDelivery | null>(orderDelivery || null);

  const setDeliverySelectStateOn = () => {
    scrollTrigger.current = true

    batch(() => {
      if(!intermediateDelivery?.type) {
        updateChanges({
          type: intermediateDelivery?.type || 0
        })
      }

      setDeliveryEditState(true)
    })
  }

  useEffect(() => {
    setDeliveryEditState(!orderDelivery)
  }, [orderDelivery])

  useEffect(() => {
    if(scrollTrigger.current) {
      scrollTrigger.current = false
      scrollPageTo('.op-delivery-item.active')
    }
  }, [deliveryEditState]);

  useEffect(() => {
    setIntermediateDelivery(orderDelivery || null)
  }, [orderDelivery]);

  const itemsWeight = useMemo(() => {
    let itemsMass =
      orderItems.reduce((prev, cur) =>
        prev + (cur.weight || 0) * cur.count * 1000
      , 0)

    itemsMass /= 1000

    return itemsMass || null
  }, [orderItems]);

  useEffect(() => {
    const initCurrentDelivery =
      orderDeliveries?.find(d => d.id === currentDeliveryId);

    setIntermediateDelivery(initCurrentDelivery || null)
  }, [currentDeliveryId])

  const deliveryChanges = useMemo(() => 
    intermediateDelivery && orderDelivery
      ? getObjectsDiffsByFields(intermediateDelivery, orderDelivery, Object.keys(orderDelivery))
      : intermediateDelivery
  , [intermediateDelivery, order]);

  useEffect(() => {
    getDeliveries()
    .then(gotDeliveries => {
      if(!gotDeliveries) {
        return;
      }

      deliveriesDispatch(gotDeliveries)
    })
  }, [])

  const dispatch = useDispatch()
  const deliveriesDispatch = (deliveries: Delivery[]) =>
    dispatch({ type: 'ORDERS_DELIVERIES', payload: deliveries })

  const setOrders = (orders: Order[]) =>
    dispatch({ type: 'ORDERS', payload: orders });

  const getDeliveries = async () => {
    const params = QueryString.stringify({
      show: true
    });

    const res = await mercheryFetch<Delivery[]>(
      'delivery?' + params,
      'GET',
      {}
    );

    if (!_isMounted.current || !validateResponse(res))
      return false;

    return res.records;
  };

  const initDeliveryCreation = (initValue?: Partial<OrderDelivery>): OrderDelivery | false => {
    if(!order) {
      return false
    }

    const newOrderDelivery: OrderDelivery = {
      id: uuidv4(),
      type: 0,
      city: null,
      city_code: null,
      term: null,
      cost: null,
      register_cost: null,
      note: null,
      delivery_service_uuid: null,
      tariff_id: null,
      pickpoint_id: null,
      store_id: null,
      point_id: null,
      point_address: null,
      shipping_date: null,
      receiving_date: null,
      registration_date: null,
      length: null,
      width: null,
      height: null,
      country_iso_num_code: null,

      ...initValue,
      postcode: null,
      order_id: order.id,
      address: initValue?.address || order.client?.address || null,
      weight: initValue?.weight || itemsWeight,
      newDelivery: true,
    }

    return newOrderDelivery
  }

  const updateChanges = (changes: Partial<OrderDelivery>) => {
    const changedDelivery = intermediateDelivery ? {
      ...intermediateDelivery,
      ...changes
    } : initDeliveryCreation(changes);

    if(!changedDelivery) {
      return false
    }

    setIntermediateDelivery(changedDelivery)
  }

  const toInitial = () => {
    if(!orderDelivery) {
      return false
    }
    batch(() => {
      setCurrentDeliveryId(order?.current_delivery_id || null)
      setOrderDeliveries(order?.deliveries || null)
      setDeliveryEditState(false)
      setIntermediateDelivery(orderDelivery)
    })
  };

  const deleteRegisteredDelivery = async () => {
    if(!intermediateDelivery) {
      return false
    }

    return await mercheryFetch<{
      orders: Order[],
      deliveries: OrderDelivery[]
    }>('orders/delivery/note', 'DELETE', {
      order_id: intermediateDelivery.order_id
    })
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) {
          return;
        }

        const updatedOrders = orders.map(order =>
          res.records.orders.find(updatedOrder =>
            order.id === updatedOrder.id
          ) || order
        )

        batch(() => {
          setOrders(updatedOrders)
          setCurrentDeliveryId(null)
        })
      })
  }

  // используется для сохранения изменений доставки по нажатию на кнопку "сохранить"
  // регистрация доставок в сервисе доставки производится в компоненте этой доставки
  // и до и после регистрации может быть вызван этот метод для сохранения изменений
  const safeHandler = async (manualChangeApplying?: true) => {
    if(!order) {
      return false
    }

    if(!deliveryChanges || Object.keys(deliveryChanges).length === 0) {
      return intermediateDelivery || false
    }

    const toCreate: boolean = Boolean(intermediateDelivery?.newDelivery);
    
    const delivery = await (
      toCreate
        ? createDeliveryRequest()
        : changeDeliveryRequest()
    )

    if(!delivery) {
      return false
    }

    !manualChangeApplying && batch(() => {
      setDeliveryEditState(false)
      updateOrderDeliveriesList(delivery)
      if(order.current_delivery_id !== currentDeliveryId) {
        orderChange({
          current_delivery_id: delivery.id
        })
      }
    })

    return delivery
  };

  function updateOrderDeliveriesList (delivery: OrderDelivery, addDeliveryAsNew?: true | undefined): false | void {
    if(!order) {
      return false
    }

    const deliveries =
      addDeliveryAsNew
        ? [...(order.deliveries || []), delivery]
        : (order.deliveries || [])
          .map(d => d.id !== delivery.id ? d : {
            ...d,
            ...delivery
          })

    updateOrderState({ deliveries })
  }

  const createDeliveryRequest = async () => {
    if(!intermediateDelivery || !intermediateDelivery.order_id) {
      return false
    }

    const res = await mercheryFetch<OrderDelivery>('orders/delivery', 'POST', {
      ...intermediateDelivery
    })

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    return res.records
  }

  const changeDeliveryRequest = async () => {
    if(!intermediateDelivery ||
      !intermediateDelivery.order_id ||
      !intermediateDelivery.id
    ) {
      return false
    }

    const res = await mercheryFetch<OrderDelivery>(
      'orders/delivery',
      'PATCH',
      {
        id: intermediateDelivery.id,
        ...deliveryChanges,
      }
    )

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    return res.records
  }

  return (
    <OrderDeliveryContext.Provider value={{
      currentDeliveryId,
      orderDeliveries,
      delivery: intermediateDelivery,
      initDeliveryCreation,
      setCurrentDeliveryId,
      updateOrderDeliveriesCollection: setOrderDeliveries,
      changeSelectedDelivery: updateChanges,
      setDeliverySelectStateOn,
      setDeliveryEditState,
      safeHandler,
      itemsWeight,
      deleteHandler: deleteRegisteredDelivery,
      updateOrderDeliveriesList
    }}>
      <div className={`order-page-delivery order-page__section ${className || ''}`}>
        <OrderDeliveryHeader
          changeDeliveryState={deliveryEditState}
          toInitial={toInitial}
        />

        {deliveryEditState
          ? <DeliveriesToSelect />
          : <SelectedDeliveryBody />
        }
      </div>
    </OrderDeliveryContext.Provider>
  );
}

export default withErrorBoundary(memo(DeliverySection), {FallbackComponent: FallbackComponent})

export interface Tariff {
  id: Id,
  price: number,
  deliveryPeriodMin?: number,
  deliveryPeriodMax?: number,
}

export type GetTariffs = () => false | Promise<void | false>
