import { ExtendedProduct, ExtendedProductStories, ExtendedProductStoriesItem, Id, ProductAttr, ProductOption, ProductVariantExtended, ProductsAttrValues } from 'merchery-lib';
import React, { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom';
import arrowLeft from 'src/img/arrow-left-white.png';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { addMessage, validateResponse } from 'src/scripts/functions';
import { useLoad } from 'src/scripts/hooks/use-load';
import useMounted from 'src/scripts/hooks/use-mounted';
import { useProductComparison } from 'src/scripts/hooks/use-product-comparison';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import useUnload from 'src/scripts/hooks/use-unload';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import TopPagePanel from '../../../_utility-components/top-page-panel';
import { ProductsAttrWithValues } from '../dto/attributes-create-response.dto';
import { ProductsAndImages } from './dto/product-and-images.dto';

function ProductTopPanel({
  pageIsCreatePage,
  initProduct,
}: {
  pageIsCreatePage: boolean,
  initProduct: React.MutableRefObject<ExtendedProduct | undefined>,
}) {
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)
  const location = useLocation<{prevPage?: string}>()
  const tabIndex = useTabIndex(2)
  const history = useHistory()
  const [load, setLoad] = useLoad()
  const _isMounted = useMounted()
  const [creationEnded, setCreationEnded] = useState(!pageIsCreatePage);

  const dispatch = useDispatch()
  const productDispatch = (product: ExtendedProduct | undefined) => 
    dispatch({ type: 'PRODUCT_ITEM', payload: product })

  const optionsDispatch = (options: ProductOption[]) => 
    dispatch({ type: 'PRODUCTS_OPTIONS', payload: options })
  const initOptionsDispatch = (options: ProductOption[]) => 
    dispatch({ type: 'PRODUCTS_INIT_OPTIONS', payload: options })

  const variantsDispatch = (variants: ProductVariantExtended[]) => 
    dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  const initVariantsDispatch = (variants: ProductVariantExtended[]) => 
    dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants })

  const storiesDispatch = (stories: ExtendedProductStories) => 
    dispatch({ type: 'PRODUCT_STORIES', payload: stories })
  const initStoriesDispatch = (stories: ExtendedProductStories) => 
    dispatch({ type: 'PRODUCT_INIT_STORIES', payload: stories })

  const setDescriptionEditing = (bool: boolean) => 
    dispatch({ type: 'PRODUCT_DETAILED_DESCRIPTION_EDITING', payload: bool });

  const {
    compareOptions,
    compareProducts,
    compareVariants,
    compareOptionsValues,
    compareStories
  } = useProductComparison(initProduct);
  const product = useAppSelector(state => state.product)
  const options = useAppSelector(state => state.productOptions)
  const initOptions = useAppSelector(state => state.initOptions)

  const variants = useAppSelector(state => state.productVariants)
  const initVariants = useAppSelector(state => state.initVariants)

  const productStories = useAppSelector(state => state.productStories)
  const initStories = useAppSelector(state => state.initStories)

  const cancelBtnHandler = () => {
    batch(() => {
      productDispatch(initProduct.current)
      optionsDispatch(initOptions)
      variantsDispatch(initVariants)
      storiesDispatch(initStories)
    })
  }

  const productChanges = compareProducts();
  const optionsChanges = compareOptions();
  const variantsChanges = compareVariants();
  const optionsValuesChanges = compareOptionsValues();
  const storiesChanges = compareStories();

  const productHasChanges = !pageIsCreatePage && (
    (productChanges && productChanges.changes.length) ||
    optionsChanges.changes.length ||
    optionsChanges.added.length ||
    optionsChanges.deleted.length ||
    variantsChanges.changes.length ||
    variantsChanges.added.length ||
    variantsChanges.deleted.length ||
    optionsValuesChanges.added.length ||
    optionsValuesChanges.deleted.length ||
    optionsValuesChanges.changes.length || 
    storiesChanges?.storiesChanges.added.length || 
    storiesChanges?.storiesChanges.changes.length || 
    storiesChanges?.itemsChanges.added.length ||
    storiesChanges?.itemsChanges.deleted.length ||
    storiesChanges?.itemsChanges.changes.length
  );

  const topPanelOpened = !!(!moySkladIntegrationOn && ((!load && productHasChanges) || !creationEnded))

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  }, topPanelOpened);

  const saveProduct = async () => {
    if(moySkladIntegrationOn || !productChanges || !productChanges.changes.length) {
      return false
    }
    
    const res = await mercheryFetch<ExtendedProduct[]>('products', 'PATCH', {
      changes: productChanges.changes
    })

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    const updatedProduct = res.records[0]

    batch(() => {
      initProduct.current = updatedProduct
      productDispatch(updatedProduct)
      setDescriptionEditing(false)
    })

    return updatedProduct
  }

  const saveChanges = async () => {
    try {
      if(!product) {
        return false
      }
      setLoad(true)

      let productId = product.id
      let mutableOptions = options;
      let mutableVariants = variants;
      let attrNamesIds: { [key: string]: Id } = Object.fromEntries(optionsValuesChanges.added.map(o => [o.name_id, o.name_id]));
      let optionsRecords: ProductOption[] | true = true
      let variantsRecords: ProductVariantExtended[] | true = true;
      const optionsFilters = {product_id: productId};
      const getRecordsFlag = {filters: {product_id: productId}};

      if(pageIsCreatePage) {
        if(!product?.name) {
          addMessage('.inputs-row.name .merchery-label', 'Название обязательно к заполнению')
          return false
        }
    
        const requestBody: ExtendedProduct = {...product}
    
        const createRes = await mercheryFetch<ProductsAndImages>('products/create', 'POST', requestBody)
        
        if(!_isMounted.current || !validateResponse(createRes)) {
          return false
        }
    
        productId = createRes.records.products.id;
      } else {
        await saveProduct()
      }


      const newAttributes = optionsChanges.added
        .filter(option => !option.notSaved)
        .map((o) => ({
          title: o.title,
          values: optionsValuesChanges.added.filter(added => added.optionId === o.id)
        }));

      const createAttrRes = newAttributes?.length && await mercheryFetch<ProductsAttrWithValues[]>('products-attributes', 'POST', {
        newAttributes: newAttributes
      })

      if(createAttrRes && _isMounted.current && validateResponse(createAttrRes) && createAttrRes.records) {
        mutableOptions = mutableOptions.map((option) => {
          const attrWithValuesIndex: number | undefined = 
            createAttrRes.records.findIndex(attr =>
              option.title === attr.title);

          if(attrWithValuesIndex === -1) {
            return option
          }

          const attr = createAttrRes.records[attrWithValuesIndex]
          attrNamesIds[option.name_id] = attr.name_id

          return {
            ...option,
            product_id: productId,
            name_id: attr.name_id,
          }
        })
      }

      if(optionsValuesChanges.added.length) {
        const newValues = optionsValuesChanges.added.map(added => ({
          ...added,
          // optionId: ,
          name_id: attrNamesIds[added.name_id],
        }))
    
        const createAttrValueRes = await mercheryFetch<ProductsAttrValues[]>('products-attributes/value', 'POST', {
          values: newValues
        })

        if(_isMounted.current && validateResponse(createAttrValueRes)) {
          const newValues = createAttrValueRes.records

          mutableOptions = mutableOptions.map((option) => ({
            ...option,
            product_id: productId,
            values: option.values.map(
              (value) => 
                newValues.find((nValue) => 
                  nValue.name_id === attrNamesIds[value.name_id] && 
                  nValue.value === value.value) 
                || value
            )
          }))
          
          for (let cIndex = 0; cIndex < newValues.length; cIndex++) {
            const element = newValues[cIndex];
            
            mutableVariants = mutableVariants.map((variant) => ({
              ...variant,
              attributes: replaceVariantAttributes(variant.attributes, [element])
            }))
          }
        } else {
          return false
        }
      }

      if(optionsValuesChanges.deleted.length) {
        const optionsWithValuesChanges = 
          options
          .filter(option => 
            !option.notCreated && 
            optionsValuesChanges.deleted.some(r => r.optionId === option.id ) )
          .map(option => ({
            id: option.id, 
            values_ids: option.values.map(v => v.id)
          }))
        
          
        if(optionsWithValuesChanges) {
          await mercheryFetch('products-options', 'PATCH', {
            options: optionsWithValuesChanges
          })
        }
      }

      if(optionsValuesChanges.changes.length) {
        await mercheryFetch('products-attributes/values', 'PATCH', {
          changes: optionsValuesChanges.changes
        })
      }

      if(optionsChanges.added?.length) {
        const withGetRecords = !optionsChanges.changes?.length && !optionsChanges.deleted?.length

        const newOptions = mutableOptions 
          .filter(chOp => 
            !chOp.notSaved && 
            optionsChanges.added.some(oAdd => chOp.id === oAdd.id)
          )
          .map(o => ({
            ...o,
            product_id: productId,
            // values_ids: []
            values_ids: o.values.map(v => v.id)
          }))

        if(newOptions.length) {
          const res = await mercheryFetch<ProductOption[] | true>('products-options', 'POST', {
            newOptions: newOptions,
            ...(withGetRecords && {
              filters: optionsFilters
            })
          })

          if(validateResponse(res)) {
            optionsRecords = res.records
          }
        } 
      }

      if(optionsChanges.changes?.length) {
        const withGetRecords = !optionsChanges.deleted?.length
        const res = await mercheryFetch<ProductOption[] | true>('products-options', 'PATCH', {
          options: optionsChanges.changes,
          ...(withGetRecords && {
            filters: optionsFilters
          })
        })
        if(validateResponse(res)) {
          optionsRecords = res.records
        }
      }
      
      if(optionsChanges.deleted?.length) {
        const res = await mercheryFetch<ProductOption[] | true>('products-options', 'DELETE', {
          id: optionsChanges.deleted.map(r => r.id),
          filters: optionsFilters
        })
        if(validateResponse(res)) {
          optionsRecords = res.records
        }
      }

      if(!_isMounted.current) {
        return false
      }

      if(optionsRecords !== true) {
        const options = optionsRecords
        batch(() => {
          optionsDispatch(options)
          initOptionsDispatch(options)
        })
      } else {
        const changedOptionsToStore = JSON.parse(JSON.stringify(mutableOptions))
        batch(() => {
          optionsDispatch(changedOptionsToStore)
          initOptionsDispatch(changedOptionsToStore)
        })
      }

      
      const mutatedVariantsToCreate = mutableVariants.filter(v => 
        variantsChanges.added.some(a => 
          a.id === v.id)
      )

      if(mutatedVariantsToCreate.length) {
        const withGetRecords = !variantsChanges.deleted?.length && !variantsChanges.changes?.length
        const res = await mercheryFetch<ProductVariantExtended[] | true>('products-variants', 'POST', {
          newVariants: mutatedVariantsToCreate.map(variant => ({
            ...variant, 
            product_id: productId
          })),
          ...(withGetRecords && getRecordsFlag)
        })
        if(validateResponse(res)) {
          variantsRecords = res.records
        }
      }

      if(variantsChanges.changes?.length) {
        const withGetRecords = !variantsChanges.deleted?.length

        const res = await mercheryFetch<ProductVariantExtended[] | true>('products-variants', 'PATCH', {
          changes: variantsChanges.changes,
          ...(withGetRecords && getRecordsFlag)
        })

        if(validateResponse(res)) {
          variantsRecords = res.records
        }
      }

      if(variantsChanges.deleted?.length) {
        const res = await mercheryFetch<ProductVariantExtended[] | true>('products-variants', 'DELETE', {
          id: variantsChanges.deleted.map(r => r.id),
          filters: {
            product_id: productId
          }
        })

        if(validateResponse(res)) {
          variantsRecords = res.records
        }
      }

      if(variantsRecords !== true && _isMounted.current) {
        variantsDispatch([...variantsRecords])
        initVariantsDispatch([...variantsRecords])
      }

      await saveStories()
    
      batch(() => {
        setCreationEnded(true)
        setDescriptionEditing(false)
        const productToDispatch: ExtendedProduct = {
          ...product,
          newProduct: false,
          id: productId
        };
        
        initProduct.current = productToDispatch
        productDispatch(productToDispatch)
      })

      if(pageIsCreatePage) {
        history.replace(`/app/products/${productId}`);
      }

    } catch (error) {
      console.log(error)
    } finally {
      setLoad(false)
    }
  }

  const saveStories = async () => {
    try {
      if(!storiesChanges || !productStories) {
        return false
      }
      let records: ExtendedProductStories = {...productStories};

      if(storiesChanges.storiesChanges.added.length) {
        const res = await mercheryFetch<ExtendedProductStories>('products-stories', 'POST', {
          ...storiesChanges.storiesChanges.added,
        })

        if(validateResponse(res)) {
          records = res.records
        }
      }

      if(
        storiesChanges.itemsChanges.changes.length || 
        storiesChanges.storiesChanges.changes.length
      ) {
        const res = await mercheryFetch<ExtendedProductStories>('products-stories', 'PATCH', {
          id: productStories.id,
          items: storiesChanges.itemsChanges.changes,
          ...storiesChanges.storiesChanges.changes,
        })

        if(validateResponse(res)) {
          records = res.records
        }
      }

      if(storiesChanges.itemsChanges.added.length) {
        const res = await mercheryFetch<ExtendedProductStoriesItem[]>('products-stories/items', 'POST', {
          items: storiesChanges.itemsChanges.added,
        })

        if(validateResponse(res) && records) {
          records.items = 
            [...records.items, ...res.records]
            .filter(item => !item.newItem)
        }
      }

      if(storiesChanges.itemsChanges.deleted?.length) {
        const res = await mercheryFetch<boolean>('products-stories/items', 'DELETE', {
          id: storiesChanges.itemsChanges.deleted.map(item => item.id),
        })

        if(validateResponse(res) && records) {
          records.items = 
            records.items.filter(item => 
              storiesChanges.itemsChanges.deleted.some(removed => 
                removed.id !== item.id)
            )
        }
      }

      if(_isMounted.current) {
        storiesDispatch(records ? {...records} : null)
        initStoriesDispatch(records ? {...records} : null)
      }
      
      return true
    } catch (error) {
      console.log(error)
      return error
    }
  }

  return (
    <TopPagePanel fixed
      topPanelOpened={topPanelOpened}
    >
      <Prompt
        when={topPanelOpened}
        message='Остались несохраненные изменения, покинуть страницу?'
      />

      <div className="left">
        {pageIsCreatePage ? 
          <Link tabIndex={tabIndex} 
            to={{
              pathname: '/app/products', 
              search: location.state?.prevPage || ''
            }} 
            className="icon-div"
          >
            <img alt="назад" src={arrowLeft} />
          </Link>
        : null}
        
        <div className="text-div">
          {productHasChanges ? `Несохраненные изменения` : 'Несохраненный товар'}
        </div>
      </div>

      <div className="right">
        {!pageIsCreatePage ?
          <MyButton
            id={'product-cancel-btn'}
            className="dark-btn"
            onClick={cancelBtnHandler}
          >
            {'Отменить'}
          </MyButton>
        : null}

        <MyButton
          id={'product-confirm-btn'}
          className="blue-btn"
          onClick={saveChanges}
        >
          {pageIsCreatePage ? 'Создать товар' : 'Сохранить'}
        </MyButton>
      </div>
      
    </TopPagePanel>
  );
}

export default ProductTopPanel;

function replaceVariantAttributes(variantAttributes: ProductAttr[], optionsNewValues: ProductsAttrValues[]): ProductAttr[] {
  const optionsLookup = Object.fromEntries(optionsNewValues.map((value) => [value.value, value]));

  const result: ProductAttr[] = [];
  for (const attr of variantAttributes) {
    const { value } = attr;
    if(!value) {
      continue;
    }

    const a = optionsLookup[value];
    if (a === undefined) {
      result.push(attr);
      continue;
    }
    if (attr.value_id === a.id && attr.attr === a.name_id) {
      result.push(attr);
      continue;
    }
    result.push({ ...attr, value_id: a.id, attr: a.name_id });
  }

  return result;
}

// {/* {!moySkladIntegrationOn ?
//   <RenderPopup
//     className={'confirm-window fixed-on-center'}
//     blackout
//     withCloseBtn
//   >
//     <h2>Удаление</h2>

//     <ul>
//       {getOptionsDeleted.length ?
//         <li>
//           <h3>
//             опций {getOptionsDeleted.map(s => `"${s.title}"`).join(', ')}
//           </h3>
//         </li>
//       : null}

//       {getOptionValuesDeleted.length ?
//         <li>
//           <h3>
//             значений {getOptionValuesDeleted.map(s => `"${s.value}"`).join(', ')}
//           </h3>
//         </li>
//       : null}

//       {getVariantsDeleted.length ?
//         <li>
//           <h3>
//             вариантов {getVariantsDeleted.map(s => `"${s.attributes.map(attr => attr.value).join(' / ')}"`).join(', ')}
//           </h3>
//         </li>
//       : null}
//     </ul>

//     <h2>
//     </h2>
//     {/* <h2>{selectedProducts.length === 1 ? 'Удаление товара ' + selectedProducts[0].name : 'Удаление товаров ' + selectedProducts.map(s => s.name).join(', ')}</h2>
//     <span>{`Подтвердите удаление ${selectedProducts.length} ${selectedProducts.length === 1 ? 'товара' : 'товаров'}`}</span>  */}

//     {/* <div className="confirm-window-btn">
//       <MyButton 
//         tabIndex={tabIndex} 
//         className={'white-btn'} 
//         onClick={closePopup}>
//         Отменить
//       </MyButton>

//       <MyButton 
//         tabIndex={tabIndex} 
//         className="red-btn" 
//         onClick={saveChanges}>
//         Удалить
//       </MyButton>
//     </div>
//   </RenderPopup>  */}
// {/* : null} */}