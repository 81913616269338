import React, {useMemo, useState} from 'react';
import { useRouteMatch } from 'react-router';
import { ImageExtended, ImageFile } from "merchery-lib"
import { SortingItem, SetDto } from "merchery-lib";
import {extractImages} from '../../../../scripts/utils/extractImages';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import transliterate from '../../../../scripts/utils/transliteration';
import MyInput from '../../../_utility-components/input/index';
import SelectInput from '../../../_utility-components/select-input/select-input';
import MyDropzone, { dropzoneAcceptAllImagePreset, dropzoneAcceptAllVideoPreset, postBase64 } from '../../../_utility-components/dropzone';
import { withDescription } from '../../categories/description';
import { SetImageModules } from '../set-page';
import {MyResponse} from "../../../../scripts/fetchConstructor";
import DropzoneContent from "../../../_utility-components/dropzone-content";
import useUrlToClipBoard from "../../../../scripts/utils/url-to-clip-board";

interface Props {
  setChanger: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  setDispatch: (setProps: Partial<SetDto>) => void, 
  sendImage: (newFiles: ImageFile[], module: SetImageModules) => Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>>,
  deleteImage: (module: SetImageModules) => Promise<false | undefined>,
  thisSet: SetDto | undefined
}

function SetSummary({
  setChanger,
  setDispatch,
  sendImage,
  deleteImage,
  thisSet,
}: Props) {
  const match = useRouteMatch<{id: string}>();
  const isNew = match.params.id === 'new';

  const [loading, setLoading] = useState<boolean>(false);
  const sorting = useAppSelector(state => state.sorting);
  const urlToClipBoard = useUrlToClipBoard(thisSet?.url);

  const extractedImages = useMemo(() => {
    return thisSet?.src ? extractImages(thisSet.src, 'small') : null
  }, [thisSet?.src])
  
  const nameChanger = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const changes: Partial<SetDto> = {
      name: value
    }
    if(isNew) {
      changes.url = transliterate(value).toLowerCase()
    }
    setDispatch(changes)
  }

  if(!thisSet) {
    return null
  }

  const fileHandler = async (files: File[]) => {
    setLoading(true);

    let imageFiles: ImageFile[] = []

    for (const file of files) {
      const isVideo = file.type.includes('video');
      const fileURL = URL.createObjectURL(file)

      const imageProps: ImageFile = {
        imageName: file.name,
        order: 1,
        src: fileURL,
        newFile: true,
        videoSource: null,
        videoLink: isVideo ? fileURL : null,
        image: isVideo ? file : String(await postBase64(file)),
      }

      imageFiles.push(imageProps)
    }

    const res = await sendImage(imageFiles, 'Set');

    setLoading(false);

    return res
  }
  
  return (
    <section className='collection-page__summary'>
      <div className='collection-page__summary__inputs-wrapper'>
        <MyInput myClassName={'grid-element'}
          name={'name'}
          required={false}
          onChange={nameChanger}
          value={thisSet.name}
        >
          <h5 className="char-label__header header-font-s">Наименование</h5>
        </MyInput>

        <MyInput myClassName={'grid-element'}
          name={'url'}
          type={"nav"}
          required={false}
          onChange={setChanger}
          value={thisSet.url}
          maxLength={45}
        >
          <h5 className="char-label__header header-font-s">URL</h5>
          <div className='possible-url text-font-s'
            onClick={urlToClipBoard}
          >
            /app/sets/<span>{thisSet.url}</span>
          </div>
        </MyInput>

        <SelectInput<SortingItem> className={'grid-element'}
          items={sorting || []}
          selectedItem={sorting?.find(v => v.id === thisSet.sorting_id)}
          selectHandler={(value: SortingItem) => setDispatch({ sorting_id: value.id })}
          inputProps={{ children: <h5 className="char-label__header header-font-s">Сортировка товаров</h5> }} />

        <SelectInput<{}> className={'grid-element'} // Сделать шаблоны
          items={[]}
          selectedItem={{}}
          selectHandler={() => {}}
          inputProps={{ children: <h5 className="char-label__header header-font-s">Шаблон</h5> }} />
      </div>

      <div className='collection-page__summary__image'>
        <MyDropzone
          files={thisSet.src ? [thisSet.src] : []}
          optionalProps={{
            multiple: false,
            maxFiles: 1,
            disabled: thisSet.newSet
          }}
          accept={[...dropzoneAcceptAllVideoPreset, ...dropzoneAcceptAllImagePreset]}
          isLoading={loading}
          disabled={thisSet?.newSet}
          fileHandler={fileHandler}
          contentRender={
            <DropzoneContent
              extractedImages={extractedImages}
              deleteImage={() => deleteImage('Set')}
              fallBackLink={thisSet.src?.src || null}
            />
          }
        />
      </div>

      <SetDescription
        description={thisSet.description}
        descriptionHandler={(desc) => setDispatch({description: desc})} 
      />
    </section>
  );
}

const SetDescription = withDescription(({
  children
}) => {
  return (
    <div className='category-page__summary__description'>
      {children}
    </div>
  )
})

export default SetSummary;