import { Id, Order } from "merchery-lib";
import {OrderDelivery} from "../dto/delivery";
import {useState, useEffect} from 'react';
import {getObjectsDiffsByFields} from "src/scripts/functions";

function useOrderDelivery(order: Order | undefined | null, deliveryId?: Id | null) {
  const [selectedDelivery, setSelectedDelivery] = useState<OrderDelivery | undefined>();

  if(!deliveryId) {
    deliveryId = order?.current_delivery_id
  }

  useEffect(() => {
    if (!order || !deliveryId || !order.deliveries) {
      setSelectedDelivery(undefined);
      return;
    }

    const findDeliveryById = (id: Id): OrderDelivery | undefined => {
      return order.deliveries?.find(d => d.id === id);
    };

    const newDelivery = findDeliveryById(deliveryId);

    if(!newDelivery && selectedDelivery) {
      setSelectedDelivery(undefined);
      return;
    } else if(!selectedDelivery && newDelivery) {
      setSelectedDelivery(newDelivery);
      return;
    }

    if(selectedDelivery && newDelivery) {
      const propertiesToCheck: (keyof OrderDelivery)[] = [
        "note", "registration_date", "register_cost", 'shipping_date', 'receiving_date', 'delivery_service_uuid'
      ];

      const diffs = getObjectsDiffsByFields(selectedDelivery, newDelivery, propertiesToCheck);
      const hasDeliveryChanged = Object.keys(diffs).length;

      if(hasDeliveryChanged) {
        setSelectedDelivery(newDelivery);
      }
      return;
    }
  }, [order?.deliveries || order?.current_delivery_id, deliveryId]);

  return selectedDelivery;
}

export default useOrderDelivery;
