import React, { useContext } from 'react';
import { Id } from 'merchery-lib';
import { OrderDelivery } from '../../dto/delivery';
import { OrderDeliveryContext } from './delivery';

interface DeliveryStatus {
  id: Id;
  className: string;
  text: string;
  deliveryField: keyof OrderDelivery;
  color: string;
}

const statuses: DeliveryStatus[] = [
  {
    id: 1,
    className: 'registration_date',
    text: 'не отгружено',
    deliveryField: 'registration_date', 
    color: '#fa8f13',
  },
  {
    id: 2,
    className: 'shipping_date',
    text: 'отгружено',
    deliveryField: 'shipping_date', 
    color: '#517EF2',
  },
  {
    id: 3,
    className: 'receiving_date',
    text: 'получено',
    deliveryField: 'receiving_date', 
    color: '#1c9e43',
  },
]

export function OrderDeliveryStatus() {
  const {
    delivery
  } = useContext(OrderDeliveryContext);

  const status = delivery?.registration_date && !delivery.shipping_date
    ? statuses[0]
    : delivery?.shipping_date
      ? statuses[1]
      : delivery?.receiving_date
        ? statuses[2]
        : null;

  if(!status) {
    return <div
      className={`wide-text-font-xxs op-delivery-status`}
      style={{
        background: '#292d38',
        color: 'white',
      }}
    >
      Оформление не завершено
    </div>
  }

  return (
    <div
      className={`wide-text-font-xxs op-delivery-status op-delivery-status__${status.className}`}
      style={{
        background: status.color,
        color: 'white',
      }}>
      {status.text}
    </div>
  )
}
