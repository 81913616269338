import React, {useMemo, useState} from "react";
import { Id } from "merchery-lib";;
import { useAppSelector } from "../../scripts/pre-type/use-selector";
import ReactTooltip from "react-tooltip";

function ManagersOnPage({
  managersOnPage,
  size
}: {
  managersOnPage: Array<Id>,
  size?: 'small' | 'large'
}) {
  const [currentManagerId, ] = useState(localStorage.getItem('admin'))
  // const { RenderButton, RenderPopup } = usePopup();
  // const admins = useAppSelector(state => state.admin);
  const filteredManagersOnPage = useMemo(() => {
    const removeCurrentManager = managersOnPage
      .sort((a,b) => +a - +b)
      // .filter(manager => manager !== +currentManagerId);

    const removeDuplicates = [...new Set(removeCurrentManager)]

    return removeDuplicates;
  }, [managersOnPage, currentManagerId])
  
  // const managersOnPage = admins && admins.filter((admin) => admin.locationOnSite === window.location.pathname)

  // if(!managersOnPage?.length) {
  //   return null
  // }

  return (
    <div className="managers-on-page sub-text hide-for-print">
      {filteredManagersOnPage
        .map((managerId) =>
          <OneManager
            key={managerId}
            managerId={managerId}
            size={size}
          />
        )}
    </div>
  );
}

export default ManagersOnPage;

const OneManager = ({
  managerId,
  size,
}: {
  managerId: Id,
  size?: 'small' | 'large'
}) => {
  const admins = useAppSelector(state => state.admin)
  const manager = admins && admins.find(el => managerId === el.id);
  if(!manager) {
    return null
  }

  const managerIcon = manager.name.charAt(0)
  const computedStyles = {
    backgroundColor: manager.color, 
    border: manager.id ? 'none' : '1px solid #e3e3e3'
  }

  return (
    <div className="manager-on-page">
      <div className={`manager-icon ${size === 'small' ? 'manager-icon_size--small' : ''} hide-for-print`}
           style={computedStyles}
           data-tip={manager.name}
           data-for={`manager-on-page_id--${managerId}`}>
        {managerIcon}
      </div>

      <ReactTooltip
        id={`manager-on-page_id--${managerId}`}
        resizeHide={true}
        place={'bottom'}
        multiline={true}
        effect={"solid"}
        isCapture={true}
      />
    </div>
  )
}