import React, {useContext, useEffect, useMemo, useState} from 'react';
import { batch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyInput from 'src/components/_utility-components/input/index';
import { OrderDeliveryContext } from '../delivery';
import { CdekDeliveryContext } from './cdek';
import { CdekCalcTariffDto } from './dto/cdek-calc-tariffs-body.api';
import { CdekWidgetAddress } from './dto/cdek-widget.dto';
import staticValues from 'src/data/static-values.json';
import CdekWidget from "src/components/main-pages/orders/order-modules/delivery/cdek/dto/cdek-widget.dto";

const cdekWidgetServiceFileURL = 'https://front2.merchery.ru/service.php';
declare global {
  interface Window {
    CDEKWidgetInstance: CdekWidget | null;
  }
}

export function CdekWidgetComponent() {
  const {
    delivery,
    changeSelectedDelivery
  } = useContext(OrderDeliveryContext);

  const {
    modes, 
    deliveryTariffs,
    cities, 
    setDeliveryTariffs,
  } = useContext(CdekDeliveryContext);

  const settings = useAppSelector(state => state.settings)

  const [
    pointTariffs,
    setPointTariffs
  ] = useState<CdekCalcTariffDto[]>([]);

  const [
    randomDateToTriggerTariffsChange,
    setRandomDateToTriggerTariffsChange
  ] = useState<null | Date>(null);

  const tariffsNotToTheDoor = useMemo(() => 
    deliveryTariffs?.filter(tariff =>
      !modes.find(mode =>
        tariff.delivery_mode === mode.code
      )?.to_the_door
  ), [deliveryTariffs, modes])

  const tariffsToTheDoor = useMemo(() => 
    deliveryTariffs?.filter(tariff =>
      modes.find(mode =>
        tariff.delivery_mode === mode.code
      )?.to_the_door
  ), [deliveryTariffs, modes])

  const cdekCityData = useMemo(() =>
    delivery && cities.find(
      city =>
        String(city.code) === delivery?.city_code
    )
  , [delivery, cities])

  useEffect(() => {
    if(pointTariffs.length) {
      setDeliveryTariffs(pointTariffs)
    }
  }, [randomDateToTriggerTariffsChange])

  const onChoose = <T extends 'door' | 'office'> (
    mode: T,
    tariff: CdekCalcTariffDto,
    address: CdekWidgetAddress<T>
  ) => {

    if ('city_code' in address) {
      batch(() => {
        changeSelectedDelivery({
          tariff_id: String(tariff.tariff_code),
          point_address: address.address,
          point_id: address.code,
          city_code: String(address.city_code),
          city: address.city,
        })
        setRandomDateToTriggerTariffsChange(new Date())
      })
    }

    window.CDEKWidgetInstance?.close()
  }

  const onCalculate = (
    tariffs: { [key in 'office' | 'door' | 'pickup']: CdekCalcTariffDto[] },
    address: {
      code?: number;
      address?: string
    }
  ) => {
    const allPointTariffs = Object.values(tariffs).flat()

    setPointTariffs(allPointTariffs)
  }

  const openWidget = async () => {
    const apiKey = staticValues.yandex_maps_api_key;

    try {
      if(!delivery || !cdekCityData) {
        return false;
      }

      if(window.CDEKWidgetInstance) {
        window.CDEKWidgetInstance?.open()
        return;
      }

      // @ts-ignore: Unreachable code error
      if(!window?.CDEKWidget) {
        throw new Error('При загрузке виджета СДЭК произошла ошибка')
      }

      const widgetConfigAndCalcData = {
        from: settings.find(setting => setting.callname === 'city')?.value,
        root: 'cdek-map',
        apiKey: apiKey,
        canChoose: true,
        servicePath: cdekWidgetServiceFileURL,
        defaultLocation: delivery?.city,
        // hideFilters: {
        //   have_cashless: false,
        //   have_cash: false,
        //   is_dressing_room: false,
        //   type: false,
        // },
        hideDeliveryOptions: {
          door: true,
        },
        popup: true,
        // debug: true,
        goods: [
          {
            width: delivery.width,
            height: delivery.height,
            length: delivery.length,
            weight: delivery.weight,
          },
        ],
        lang: 'rus',
        currency: 'RUB',
        tariffs: {
          office: tariffsNotToTheDoor
            ? tariffsNotToTheDoor.map(tariff => tariff.tariff_code)
            : [],
          door: tariffsToTheDoor
            ? tariffsToTheDoor.map(tariff => tariff.tariff_code)
            : [],
        },
        onCalculate: onCalculate,
        onChoose: onChoose
      };

      // @ts-ignore: Unreachable code error
      window.CDEKWidgetInstance = await new window.CDEKWidget(widgetConfigAndCalcData)
      window.CDEKWidgetInstance?.open()
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  useEffect(() => {
    return () => {
      window.CDEKWidgetInstance?.close()
    }
  }, [])

  return (
    <MyInput
      id="delivery-point-address"
      name="delivery-point-address"
      myClassName='delivery-point-address-input'
      style={{
        cursor: 'pointer'
      }}
      placeholder={delivery?.point_id || 'Выбрать пункт выдачи'}
      value={delivery?.point_address || ''}
      readOnly={true}
      onClick={openWidget}
      innerChildren={
        <i className="icofont-ui-edit merchery-input__icon"></i>
      }
    />
  )
}
