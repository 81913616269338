import React, { useEffect, useState } from 'react';
import { Id, ExtendedProduct, ProductVariantExtended } from "merchery-lib";
import { calcPrice, toastUp, uuidv4 } from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import { ProductVariantRow } from '../../products/products-items/product-items';
import { productLikeFields } from '../../products/products-items/product-items-utils';
import { CanBeAddedProduct } from './can-be-added-product.dto';
import { countOverRemainMessage } from './product-row-to-be-added';

interface VariantRowToBeAddedProps {
  product: ExtendedProduct,
  variant: ProductVariantExtended,
  canBeAddedSelected: CanBeAddedProduct[],
  setCanBeAddedSelected: (items: CanBeAddedProduct[]) => void,
}

function VariantRowToBeAdded({
  product,
  variant,
  canBeAddedSelected,
  setCanBeAddedSelected,
}: VariantRowToBeAddedProps) {
  const showRemain = useAppSelector(state => state.settings.find(setting => setting.callname === 'remainder'))?.value
  const items = useAppSelector(state => state.productItemsInContext);
  const [count, setCount] = useState(0);
  const tabIndex = useTabIndex(2)

  useEffect(() => {
    if(canBeAddedSelectedVariant && canBeAddedSelectedVariant?.count !== count) {
      changeCanBeAddedSelected(canBeAddedSelectedVariant.id, {
        count: count,
        total: +calcPrice([{count, price: product.price}]),
      })
    }
    if(!canBeAddedSelectedVariant && count > 0) {
      addSelected()
    }
    if(canBeAddedSelectedVariant && count === 0) {
      removeSelected()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const variantPredicate: <T extends {product: Id, variant_id: Id | null},>(value: T, index: number, obj: T[]) => unknown = arrayItem =>
    arrayItem.product === variant.product_id && arrayItem.variant_id === variant.id

  const canBeAddedSelectedVariant = canBeAddedSelected?.find(variantPredicate);
  const externalIdForLabels = !canBeAddedSelectedVariant
    ? variant.external_id
    : canBeAddedSelectedVariant.external_id;

  const remainForLabels = !canBeAddedSelectedVariant
    ? variant.remain
    : canBeAddedSelectedVariant.remain;

  const alreadyAdded = items.some(variantPredicate)
  const disabled = Boolean(alreadyAdded || (!!showRemain && !remainForLabels));

  const addSelected = (variantFromHandlerRow?: ProductVariantExtended) => {
    const variantToBeAdded = variantFromHandlerRow || variant;
    setCanBeAddedSelected([...canBeAddedSelected, productToItem(variantToBeAdded)])
  }

  const checkboxHandler = (variantFromHandlerRow: ProductVariantExtended) => {
    const selected = canBeAddedSelected.some(item => item.variant_id === variantFromHandlerRow.id)

    if(selected) {
      setCount(0)
    } else {
      setCount(1)
    }
  }

  const removeSelected = () => {
    setCanBeAddedSelected(
      canBeAddedSelected.filter(item =>
        item.variant_id !== variant.id)
    )
  }
  
  const changeCanBeAddedSelected = (id: Id, changes: Partial<CanBeAddedProduct>) => {
    setCanBeAddedSelected(canBeAddedSelected.map(item => item.id === id ? {...item, ...changes} : item))
  }

  const itemIsNotVariant = (variant: ProductVariantExtended | CanBeAddedProduct): variant is CanBeAddedProduct => 
    (variant as CanBeAddedProduct).variant_id !== undefined
  

  const productToItem = (variant: CanBeAddedProduct | ProductVariantExtended): CanBeAddedProduct => {
    const isNotVariant = itemIsNotVariant(variant)

    if(isNotVariant) {
      return {
        ...variant,
        count: count,
        price_with_discount: +calcPrice([{count: 1, price: variant.price, cutDigits: 2}]),
        total: +calcPrice([{count, price: variant.price, cutDigits: 2}]),
      }
    }

    const variantPrice = variant.price !== null 
      ? variant.price 
      : product.price;

    return {
      ...product,
      ...variant,
      price: variantPrice,
      attributes: variant.attributes,
      id: uuidv4(),
      src: variant.src.find(image => image.main) || variant.src[0],
      link: null,
      count: count,
      total: +calcPrice([{
        count, 
        price: variantPrice
      }]),
      returned: 0,
      created: '',
      modified: '',
      variant_id: variant.id,
      price_with_discount: variant.price !== null ? variant.price : 0,
      product: variant.product_id,
    }
  }

  const counter = (newCount: number) => {
    if(canBeAddedSelectedVariant &&
      newCount === canBeAddedSelectedVariant.remain &&
      newCount === canBeAddedSelectedVariant.count
    ) {
      const message = countOverRemainMessage(canBeAddedSelectedVariant.remain)
      toastUp(message)
    }
    setCount(newCount)
  }

  const shownVariantFields = [
    'emptyGap',
    'checkbox',
    'src',
    'attributes',
    {
      key: 'external_id',
      options: {
        remain: remainForLabels,
        external_id: externalIdForLabels,
        showRemain: false
      }},
    {
      key: 'count',
      options: {
        count,
        counter: counter,
        remain: remainForLabels
      }},
    'price'
  ] as const;

  return <ProductVariantRow
    item={variant}
    className={'row-item order-add-items-rows--variant  order-add-items-rows--template'}
    shownFields={shownVariantFields}
    changer={undefined}
    disabled={disabled}
    selectHandler={checkboxHandler}
    tabIndex={tabIndex}
    selected={canBeAddedSelectedVariant !== undefined || alreadyAdded} 
    componentsFields={productLikeFields}
  />
}

export default VariantRowToBeAdded;