import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import useMounted from 'src/scripts/hooks/use-mounted';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { querify, validateResponse } from '../../../scripts/functions';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import { NotFoundLocalApp } from '../../_utility-components/not-found';
import PageLoading from '../../_utility-components/page-loading';
import { MainRouteChild } from '../main-page';
import { ProductItem, Order, RefundDto, ClientDto } from 'merchery-lib';
import { ClientHeader } from './client-header';
import ClientHistory from './modules/client-history';
import ClientSummary from './modules/summary';

interface Props extends MainRouteChild {
}

function ClientPage(props: Props) {
  const _isMounted = useMounted()
  const match = useRouteMatch<{id: string}>();
  const currentClientId = +match.params.id;
  const orders = useAppSelector(state => state.orders);
  const clients = useAppSelector(state => state.clients);

  const currentClient = useMemo(() =>
    clients &&
    clients.find(c =>
      c.id === currentClientId
    ), [clients, currentClientId]
  );

  const refunds = useAppSelector(state => state.refunds)

  const refundsDispatch = (clientRefunds: RefundDto[]) => dispatch({ 
    type: 'ORDERS_REFUNDS', 
    payload: 
      [...(refunds || []), ...clientRefunds]
      .reduce<RefundDto[]>((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
  })

  const dispatch = useDispatch();
  const clientsDispatch = (clients: ClientDto[]) => dispatch({type: 'CLIENTS', payload: clients});
  const ordersDispatch = (clientOrders: Order[]) => dispatch({
    type: 'ORDERS', 
    payload: [
      ...new Set([
        ...orders.map(order => order.id), 
        ...clientOrders.map(order => order.id)
      ])
    ]
    .map(orderId =>
      clientOrders.find(order => order.id === orderId) || 
      orders.find(order => order.id === orderId)
    )
  });
  const itemsDispatch = (items: ProductItem[]) => dispatch({type: 'PRODUCT_ITEMS_IN_CONTEXT', payload: items});

  const thisClientDispatch = (client: ClientDto) => clientsDispatch(clients?.length ? clients.map(c => c.id !== client.id ? c : client) : [client])

  const [loaded, setLoaded] = useState(!!currentClient);

  const getClient = async () => {
    const res = await mercheryFetch<ClientDto | null>(`client/one/${currentClientId}`, 'GET')

    setLoaded(true)

    if(!_isMounted.current || !validateResponse(res)) return false

    const client = res.records
    if(!client) return false

    thisClientDispatch(client)
  }

  const getOrdersAndItems = async () => {
    const queryOrder = querify({
      filters: {
        client_id: currentClientId,
        complete: true
      }
    });
    const orderRes = await mercheryFetch<Order[]>(`orders?${queryOrder}`, 'GET')

    if(!_isMounted.current || !validateResponse(orderRes)) { 
      return false
    }
    ordersDispatch(orderRes.records)

    const queryItems = querify({
      order: orderRes.records.map(o => o.id)
    });

    if(queryItems) {
      const itemsRes = await mercheryFetch<ProductItem[]>(`orders/item?${queryItems}`, 'GET')

      if(!_isMounted.current || !validateResponse(itemsRes)) {
        return false
      }
      itemsDispatch(itemsRes.records)
    }
  }

  const getRefunds = async () => {
    const query = querify({
      filters: {
        client_id: currentClientId,
      }
    });
    const res = await mercheryFetch<RefundDto[]>(`orders/refund?${query}`, 'GET')
    if(_isMounted.current && validateResponse(res)) {
      refundsDispatch(res.records)
    }
  }

  useEffect(() => {
    props.setCurrentPage('clients')
    
    getClient()
    getOrdersAndItems()
    getRefunds()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageLoading
      loaded={loaded}
    >
      {currentClient ? 
        <div className='client-page'>
          <ClientHeader />

          <ClientSummary client={currentClient}/>
          
          <ClientHistory client={currentClient}/>
        </div>
      : <NotFoundLocalApp
        optionalMessage={'покупатель не найден'}
      />}
    </PageLoading>
  );
}

export default ClientPage;