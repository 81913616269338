import React, {memo, useContext, useMemo} from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { usePopup } from '../../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../../_utility-components/button/button';
import {CharGroupsDto, CharsLabelDto, MultiSelectDto, MultiSelectValueName, Id} from 'merchery-lib';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import {getArrayOfObjectsChanges, objOfArraysHasProps} from "src/scripts/functions";
import {
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";
import {batch, useDispatch} from "react-redux";
import charEntitiesPaths
  from "src/components/main-pages/products/product-page-modules/characteristics-modules/char-entities-paths";
import useMounted from "src/scripts/hooks/use-mounted";

function CharFooter() {
  const _isMounted = useMounted();
  const {
    charGroups: groups,
    multiSelectNames,
    charGroupsDiff,
    labelsDiff,
    setCharGroups,
    initialCharGroups,
    initialLabels,
    multiSelects: allMultiSelect,
    setMultiSelects,
    setMultiSelectNames,
    setCharEditingId,
    postReq,
    patchReq,
    deleteReq,
    setInitialCharGroups,
    setInitialLabels,
    initialMultiSelects,
    initialMultiSelectsNames,
    setInitialMultiSelects,
    setInitialMultiSelectsNames,
    multiSelectValuesDiff,
  } = useContext(CharacteristicsContext);

  const {
    thisCharGroup,
    charLabels,
    multiSelectDiff,
    charMultiSelects,
  } = useContext(OneCharContext);

  const initialCharGroup = useMemo(() =>
    initialCharGroups.find(gr => gr.id === thisCharGroup?.id)
  , [initialCharGroups, thisCharGroup]);

  const allLabels = useAppSelector(state => state.labels);
  const categories = useAppSelector(state => state.categoriesAssociatedWithProduct);

  const labels = useMemo(() =>
    allLabels.filter(l =>
      l.char_group === thisCharGroup?.id)
  , [allLabels])

  const labelsReducerType = 'CHARS_LABELS';
  const dispatch = useDispatch()
  const setLabels = (value: CharsLabelDto[]) =>
    dispatch({type: labelsReducerType, payload: value})

  const {RenderPopup, closePopup, isOpen, openPopup} = usePopup()

  const findNameDuplicates = (items: {name: string}[]) =>
    items.filter((item, i, array) =>
      array.findIndex(secondItem =>
        secondItem.name.toLowerCase() === item.name.toLowerCase()
      ) === i
  )

  const nothingToSave = useMemo(() =>
    charGroupsDiff &&
    !(
      [Object.values(charGroupsDiff), Object.values(labelsDiff)]
        .flat(2)
        .length
    )
  , [charGroupsDiff, labelsDiff])

  const groupsHaveSameName = useMemo(() =>
    groups.length !== findNameDuplicates(groups).length
  , [groups]);

  const labelsHaveSameName = useMemo(() =>
    labels.length !== findNameDuplicates(labels).length
  , [labels]);

  const msNamesHaveSameName = useMemo(() =>
    multiSelectNames.length !== findNameDuplicates(multiSelectNames).length
  , [multiSelectNames]);

  const categoryName = useMemo(() =>
    categories.find(c => c.id === thisCharGroup?.category_id)?.name
  , [thisCharGroup, categories]);

  const disabled = !thisCharGroup?.name ||
    !labels.length ||
    groupsHaveSameName ||
    labelsHaveSameName ||
    msNamesHaveSameName ||
    (thisCharGroup.newGroup && nothingToSave);

  const multiSelectNamesDiff = useMemo(() =>
    getArrayOfObjectsChanges(multiSelectNames, initialMultiSelectsNames, ['name'])
  , [multiSelectNames, initialMultiSelectsNames]);

  console.log('multiSelectDiff', multiSelectDiff)
  console.log('multiSelectNamesDiff', multiSelectNamesDiff)
  console.log('multiSelectValuesDiff', multiSelectValuesDiff)
  
  const cancelCharGroup = () => {
    if(groups.filter(g => g.newGroup)) {
      setCharGroups(
        groups.filter(group =>
          !group.newGroup
        )
      )
    } else if(objOfArraysHasProps(charGroupsDiff)) {
      const updatedList = groups.map(group => group.id !== thisCharGroup?.id ? group : {
        ...group,
        ...initialCharGroup
      })
      setCharGroups(updatedList)
    }

    if(objOfArraysHasProps(labelsDiff)) {
      const labelsWithoutChangesInThisGroup = charLabels.flatMap(l => {
        if(l.char_group === thisCharGroup?.id && l.newLabel)
          return []
        if(l.char_group === thisCharGroup?.id)
          return initialLabels.find(initL => initL.id === l.id) || []
        return l
      })
      labelsWithoutChangesInThisGroup.push(
        ...initialLabels.flatMap(initLabel =>
          labelsDiff.deleted.some(deleted =>
            initLabel.id === deleted)
            ? initLabel
            : []
        )
      )
      setLabels(labelsWithoutChangesInThisGroup)
    }

    if(objOfArraysHasProps(multiSelectDiff)) {
      const multiSelectsWithoutChangesInThisGroup = allMultiSelect.flatMap(ms => {
        if(charLabels.some(l => l.char_group === thisCharGroup?.id && l.id === ms.label_id)) {
          if(ms.newMultiSelect) return []
          return initialMultiSelects.find(initMs => initMs.id === ms.id) || []
        }
        return ms
      })

      multiSelectsWithoutChangesInThisGroup.push(
        ...initialMultiSelects.flatMap(initms =>
          multiSelectDiff.deleted.some(deleted =>
            initms.id === deleted.id
          ) ? initms : []
        )
      )

      setMultiSelects(multiSelectsWithoutChangesInThisGroup)
    }

    if(multiSelectNames.filter(name => name.newName).length) {
      setMultiSelectNames(
        multiSelectNames.filter(name => !name.newName)
      )
    }

    setCharEditingId(null)
  }

  const saveCharGroup = async () => {
    const createGroup = thisCharGroup?.newGroup;
    const groupChanges = charGroupsDiff?.changes.find(gr => gr.id === thisCharGroup?.id);
    const labelsToCreate = labelsDiff.added.filter(l => l.char_group === thisCharGroup?.id);
    const labelsChanges = labelsDiff.changes.filter(chl => charLabels.find(l => l.id === chl.id));
    const labelsToDelete = labelsDiff.deleted.filter(labelToDelete => initialLabels.some(l => l.id === labelToDelete));

    console.log('labelsToCreate', labelsToCreate)
    console.log('labelsChanges', labelsChanges)
    console.log('labelsToDelete', labelsToDelete)

    let updatedListOfGroups: CharGroupsDto[] | undefined = undefined;
    let updatedLabels = charLabels;
    let updatedMultiSelect = charMultiSelects;
    let updatedMultiSelectNames = multiSelectNames;

    let labelIdsForReplace: {[id: Id]: Id | undefined} = {};
    let namesIdsForReplace: {[id: Id]: Id | undefined} = {};

    if(createGroup) {
      const response = await postReq<CharGroupsDto[]>(charEntitiesPaths.charGroupsRoutePath, [thisCharGroup]);
      if(!_isMounted.current || !response?.success) return false

      updatedListOfGroups = [
        ...groups.filter(gr =>
          gr.id !== thisCharGroup.id
        ),
        response.records[0],
      ]
    }

    if(labelsToCreate.length) {
      const labelsWithReplacedIds = labelsToCreate.map(value => ({
        ...value,
        char_group: updatedListOfGroups
          ? updatedListOfGroups[updatedListOfGroups.length - 1].id
          : value.char_group
      }))
      const res = await postReq<CharsLabelDto[]>(charEntitiesPaths.labelsRoutePath, labelsWithReplacedIds)
      if(!_isMounted.current || !res?.success) return false;

      for (const label of labelsToCreate) {
        labelIdsForReplace[label.id] = res.records.find(createdLabel =>
          createdLabel.name === label.name)?.id
      }
      updatedLabels = updatedLabels.map(label =>
        res.records.find(nl =>
          nl.order === label.order) || label
      )
    }

    if(!createGroup && groupChanges) {
      const res = await patchReq<CharGroupsDto[]>(charEntitiesPaths.charGroupsRoutePath, [groupChanges])
      if(!_isMounted.current || !res?.success) return false

      updatedListOfGroups = groups.map(gr =>
        gr.id !== thisCharGroup?.id
          ? gr
          : res.records[0]
      )
    }

    if(labelsChanges.length) {
      const res = await patchReq<CharsLabelDto[]>(charEntitiesPaths.labelsRoutePath, labelsChanges);
      if(!_isMounted.current || !res?.success) return false;

      updatedLabels = updatedLabels.map(label =>
        res.records.find(nl =>
          nl.id === label.id) || label
      );
    }

    if(labelsToDelete.length) {
      const res = await deleteReq(charEntitiesPaths.labelsRoutePath, labelsToDelete);
      if(!_isMounted.current || !res?.success) return false

      updatedLabels = updatedLabels.filter(label => !labelsToDelete.some(nl => nl === label.id) || label)
    }

    const msNamesToCreateValidated = multiSelectNamesDiff.added.filter(msName => msName.name)

    if(msNamesToCreateValidated.length) {
      const namesResponse = await postReq<MultiSelectValueName[]>(charEntitiesPaths.msNamesRoutePath, msNamesToCreateValidated);
      if(!_isMounted.current || !namesResponse?.success) return false

      for (const name of msNamesToCreateValidated) {
        const createdNameFromDb = namesResponse.records.find(createdName =>
          createdName.name === name.name
        )

        if(createdNameFromDb?.id) {
          namesIdsForReplace[name.id] = createdNameFromDb.id
        }
      }
      updatedMultiSelectNames = [
        ...updatedMultiSelectNames.filter(name =>!name.newName),
        ...namesResponse.records
      ]
    }

    if(multiSelectNamesDiff.changes.length) {
      const res = await patchReq<MultiSelectValueName[]>(charEntitiesPaths.msNamesRoutePath, multiSelectNamesDiff.changes);
      if(!_isMounted.current || !res?.success) return false

      updatedMultiSelectNames = updatedMultiSelectNames.map(name =>
        res.records.find(nl => nl.id === name.id) || name)
    }

    if(multiSelectDiff.added.length) {
      const msWithUpdatedNameIds: MultiSelectDto[] = multiSelectDiff.added.map(value => ({
        ...value,
        names:
          value.names
            .split(', ')
            .map(name =>
              Number(namesIdsForReplace[name] || name ))
            .filter(a => !isNaN(a)),
        label_id: labelIdsForReplace[value.label_id] || value.label_id,
      }))
      const res = await postReq<MultiSelectDto[]>(charEntitiesPaths.msRoutePath, msWithUpdatedNameIds)
      if(!_isMounted.current || !res?.success) return false;

      updatedMultiSelect = [
        ...updatedMultiSelect.filter(ms => !ms.newMultiSelect),
        ...res.records
      ]
    }

    if(multiSelectDiff.changes.length) {
      const msWithUpdatedNameIds = multiSelectDiff.changes.map(value => ({
        ...value,
        ...(value.names &&
          {names:
            value.names
              .split(', ')
              .map(name =>
                Number(namesIdsForReplace[name] || name ))
              .filter(a => !isNaN(a))
          }
        ),
      }))

      const res = await patchReq<MultiSelectDto[]>(charEntitiesPaths.msRoutePath, msWithUpdatedNameIds);
      if(!_isMounted.current || !res?.success) return false;

      updatedMultiSelect = updatedMultiSelect.map(label =>
        res.records.find(nl =>
          nl.id === label.id) || label
      );
    }

    if(multiSelectDiff.deleted.length) {
      const res = await deleteReq(charEntitiesPaths.msRoutePath, multiSelectDiff.deleted.map(item => item.id));
      if(!_isMounted.current || !res?.success) return false

      updatedMultiSelect = updatedMultiSelect.filter(name => !multiSelectDiff.deleted.some(nl => nl.id === name.id) || name)
    }

    batch(() => {
      if(objOfArraysHasProps(charGroupsDiff) && updatedListOfGroups) {
        setCharGroups(updatedListOfGroups)
        setInitialCharGroups(updatedListOfGroups)
      }

      if(objOfArraysHasProps(labelsDiff)) {
        setLabels(allLabels.map(l => updatedLabels.find(upl =>labelIdsForReplace[l.id] === upl.id) || l))
        setInitialLabels(updatedLabels)
      }

      if(objOfArraysHasProps(multiSelectDiff)) {
        setMultiSelects(updatedMultiSelect)
        setInitialMultiSelects(updatedMultiSelect)
      }

      if(objOfArraysHasProps(multiSelectNamesDiff)) {
        setMultiSelectNames(updatedMultiSelectNames)
        setInitialMultiSelectsNames(updatedMultiSelectNames)
      }

      setCharEditingId(null)
    })
  }

  const saveBtnHandler = nothingToSave
    ? saveCharGroup
    : openPopup;

  return (
    <div className='characteristics-bottom__btn-wrapper'>

      {isOpen ? 
        <RenderPopup
          className={'confirm-popup fixed-on-center'}
          tabIndexDeep={3}
          withCloseBtn
          withBlackout
        >
          <h3 className='confirm-popup__h header-font-xl'>
            Подтвердите сохранение шаблона
          </h3>

          <div className='confirm-popup__body'>
            <div className='confirm-popup__paragraph'>
              Шаблон группы характеристик «<b>{thisCharGroup?.name || ''}</b>» связан с категорией товаров «<b>{categoryName}</b>».
            </div>

            <div className='confirm-popup__paragraph'>
              Шаблон характеристик будет <b>обновлен</b> для всей категории товаров, 
              а значения измененных характеристик будут сброшены для всех товаров, 
              расположенных в категории «<b>{categoryName}</b>» и ее дочерних категориях.
            </div>

            <div className='confirm-popup__paragraph'>
              Вы уверены, что хотите продолжить?
            </div>
          </div>

          <div className='confirm-popup__btn-wrapper'>
            <MyButton className={'white-btn width-228'}
              onClick={closePopup}
            >
              Назад
            </MyButton>

            <MyButton className={`blue-btn width-228`}
              onClick={() => {
                saveCharGroup();
                closePopup();
              }}
            >
              Да, сохранить изменения
            </MyButton>
          </div>
        </RenderPopup>
      : null}
      
      <MyButton
        disabled={disabled}
        className={'blue-btn btn_side-padding_36'}
        onClick={saveBtnHandler}
      >
        Сохранить
      </MyButton>

      <MyButton
        className={'white-btn btn_side-padding_36'}
        onClick={cancelCharGroup}
      >
        Отменить
      </MyButton>
    </div>
  )
}

export default memo(CharFooter)